import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Pagination, ConfigProvider, Table } from "antd";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
// import { Link } from "react-router-dom";
import { writeFile, utils } from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
import url from "./BaseUrl";
import ex from "../assets/icon-report/export.svg";
import print from "../assets/icon-report/print.svg";
import money from "../assets/icon-report/money.svg";
import kons from "../assets/icon-report/kons.svg";
import kategori from "../assets/icon-report/Kategori.svg";
import sub from "../assets/icon-report/sub.svg";
import sopir from "../assets/icon-report/Sopir.svg";
// import sampah from "../assets/icon-report/sampah.svg";
import pegawai from "../assets/icon-report/pegawai.svg";
import bank from "../assets/icon-report/bank.svg";
import unit from "../assets/icon-report/unit.svg";
import sup from "../assets/icon-report/sup.svg";
import truk from "../assets/icon-report/Truck.svg";
import moneyW from "../assets/icon-report/moneyW.svg";
import konsW from "../assets/icon-report/konsW.svg";
import kategoriW from "../assets/icon-report/KategoriW.svg";
import subW from "../assets/icon-report/subW.svg";
import sopirW from "../assets/icon-report/SopirW.svg";
// import sampahW from "../assets/icon-report/sampahW.svg";
import pegawaiW from "../assets/icon-report/pegawaiW.svg";
import bankW from "../assets/icon-report/bankW.svg";
import unitW from "../assets/icon-report/unitW.svg";
import supW from "../assets/icon-report/supW.svg";
import trukW from "../assets/icon-report/TruckW.svg";

const Report = () => {
  // const [waste, setWaste] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [all, setAll] = useState(1);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const accToken = localStorage.getItem("token");
  const history = useNavigate();
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [filteredWaste, setfilteredWaste] = useState("");
  const [links, setLinks] = useState("transactions");
  const [title, setTitle] = useState("Transaksi");

  let columns = [];

  if (links === "transactions") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Pelanggan",
        dataIndex: "user_name",
        data: "user_name",
        width: 200,
        fixed: "left",
      },
      {
        title: "Nama Bank Unit",
        dataIndex: "unit_bank_name",
        data: "unit_bank_name",
        width: 200,
      },
      {
        title: "Jenis Sampah",
        dataIndex: "subcategory_name",
        data: "subcategory_name",
        width: 100,
      },
      {
        title: "Tanggal",
        dataIndex: "date_of_transaction",
        data: "date_of_transaction",
        width: 150,
        key: "date_of_transaction",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
      {
        title: "Berat",
        width: 100,
        dataIndex: "weight",
        data: "weight",
      },
      {
        title: "Total Harga",
        width: 100,
        dataIndex: "amount",
        data: "amount",
      },
    ];
  } else if (links === "consumers") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Pelapak",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Alamat",
        dataIndex: "address",
        width: 200,
      },
      {
        title: "Kota",
        dataIndex: "city",
        width: 180,
      },
      {
        title: "No. HP",
        width: 150,
        dataIndex: "phone",
      },
      {
        title: "No. Telphone",
        width: 150,
        dataIndex: "company_phone_number",
        key: "date_of_birth",
      },
      {
        title: "fax",
        width: 150,
        dataIndex: "fax",
      },
    ];
  } else if (links === "wastes") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Jenis Sampah",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Berat",
        dataIndex: "weight",
        width: 100,
      },
      {
        title: "Harga Beli",
        dataIndex: "purchase_price",
        width: 150,
      },
      {
        title: "Harga Jual",
        width: 100,
        dataIndex: "selling_price",
      },
      {
        title: "Berat Minimal",
        width: 150,
        dataIndex: "limit_minimal",
      },
      {
        title: "Pengolahan",
        width: 150,
        dataIndex: "treatment",
      },
      {
        title: "Tanggal Registrasi",
        width: 150,
        dataIndex: "date_of_registration",
        key: "date_of_transaction",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
    ];
  } else if (links === "employees") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Pegawai",
        dataIndex: "full_name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 200,
      },
      {
        title: "NIK",
        dataIndex: "nik",
        width: 180,
      },
      {
        title: "Tempat Lahir",
        width: 100,
        dataIndex: "place_of_birth",
      },
      {
        title: "Tanggal Lahir",
        width: 150,
        dataIndex: "date_of_birth",
        key: "date_of_birth",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
      {
        title: "Jenis Kelamin",
        width: 150,
        dataIndex: "gender",
      },
      {
        title: "No Telepon",
        width: 150,
        dataIndex: "phone",
      },
      {
        title: "Tanggal Registrasi",
        width: 150,
        dataIndex: "date_of_registration",
        key: "date_of_registration",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
    ];
  } else if (links === "main-banks") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Bank",
        dataIndex: "name",
        width: 150,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Alamat Bank",
        dataIndex: "address",
        width: 200,
      },
      {
        title: "Latitude",
        dataIndex: "latitude",
        width: 100,
      },
      {
        title: "Longitude",
        dataIndex: "longitude",
        width: 150,
      },
    ];
  } else if (links === "unit-banks") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Bank Unit",
        dataIndex: "name",
        width: 200,
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
        fixed: "left",
      },
      {
        title: "Alamat Bank",
        dataIndex: "address",
        width: 200,
      },
      {
        title: "Nama Bank Utama",
        dataIndex: "mainBankName",
        width: 200,
      },
      {
        title: "Latitude",
        dataIndex: "latitude",
        width: 100,
      },
      {
        title: "Longitude",
        dataIndex: "longitude",
        width: 150,
      },
    ];
  } else if (links === "suppliers") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Alamat",
        dataIndex: "address",
        width: 200,
      },
      {
        title: "Kota",
        dataIndex: "city",
        width: 200,
      },
      {
        title: "No. Telp",
        dataIndex: "company_phone_number",
        width: 200,
      },
      {
        title: "No. HP",
        dataIndex: "phone",
        width: 200,
      },
      {
        title: "Fax",
        dataIndex: "fax",
        width: 200,
      },
      {
        title: "Tanggal Daftar",
        width: 150,
        dataIndex: "created_at",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
    ];
  } else if (links === "categories") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Deskripsi",
        dataIndex: "description",
        width: 200,
      },
      {
        title: "Slug",
        dataIndex: "slug",
        width: 200,
      },
      {
        title: "Icon",
        dataIndex: "icon",
        width: 100,
        render: (icon) => (
          <img
            type="file"
            src={`https://gateway.indocircularwaste.co.id/storage/${icon}`}
            alt="icon"
            style={{ maxWidth: "30px", maxHeight: "30px" }}
          />
        ),
      },
    ];
  } else if (links === "subcategories") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Sub-Kategori",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "Nama Kategori",
        dataIndex: "category_name",
        width: 200,
      },
      {
        title: "Deskripsi",
        dataIndex: "description",
        width: 200,
      },
      {
        title: "Berat",
        dataIndex: "weight",
        width: 100,
      },
      {
        title: "Harga Beli",
        dataIndex: "purchase_price",
        width: 150,
      },
      {
        title: "Harga Jual",
        width: 100,
        dataIndex: "selling_price",
      },
      {
        title: "Berat Minimal",
        width: 150,
        dataIndex: "limit_minimal",
      },
      {
        title: "Pengolahan Pada Sampah",
        width: 150,
        dataIndex: "treatment",
      },
      // {
      //   title: "Tanggal Registrasi",
      //   width: 150,
      //   dataIndex: "date_of_registration",
      //   key: "date_of_transaction",
      //   render: (text) => {
      //     const date = new Date(text);
      //     const day = String(date.getDate()).padStart(2, "0");
      //     const month = String(date.getMonth() + 1).padStart(2, "0");
      //     const year = date.getFullYear();
      //     const formattedDate = `${day}/${month}/${year}`;
      //     return formattedDate;
      //   },
      // },
      {
        title: "Icon",
        dataIndex: "icon",
        width: 100,
        render: (icon) => (
          <img
            type="file"
            src={`https://gateway.indocircularwaste.co.id/storage/${icon}`}
            alt="icon"
            style={{ maxWidth: "30px", maxHeight: "30px" }}
          />
        ),
      },
    ];
  } else if (links === "drivers") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Sopir",
        dataIndex: "name",
        width: 200,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
      },
      {
        title: "NIK",
        dataIndex: "nik",
        width: 200,
      },
      {
        title: "No Telepon",
        dataIndex: "phone",
        width: 200,
      },
      {
        title: "Alamat",
        dataIndex: "address",
        width: 200,
      },
      {
        title: "Tempat Lahir",
        dataIndex: "place_of_birth",
        width: 200,
      },
      {
        title: "Tanggal Lahir",
        dataIndex: "date_of_birth",
        width: 200,
        key: "date_of_birth",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
      {
        title: "Email Sopir",
        dataIndex: "email",
        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 100,
      },
    ];
  } else if (links === "tpa-transactions") {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Sopir",
        dataIndex: "driver_name",
        data: "driver_name",
        width: 200,
        fixed: "left",
      },
      {
        title: "Angkutan",
        dataIndex: "vehicle_plat_number",
        data: "vehicle_plat_number",
        width: 200,
      },
      {
        title: "Berat Sampah",
        dataIndex: "weight",
        data: "weight",
        width: 100,
      },
      {
        title: "Tanggal",
        dataIndex: "created_at",
        data: "created_at",
        width: 150,
        key: "created_at",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
      {
        title: "Jam",
        dataIndex: "start_at",
        render: (text, record) => <span>{record.start_at}</span>,
        width: 100,
      },
    ];
  } else {
    columns = [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
      {
        title: "Nama Angkutan",
        dataIndex: "name",
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
        //  width: 200
      },
      {
        title: "Tipe",
        dataIndex: "type",
        //  width: 200
      },
      {
        title: "Plat Nomor",
        dataIndex: "plat_number",
        //  width: 200,
      },
      {
        title: "Deskripsi",
        dataIndex: "description",
        //  width: 200
      },
      {
        title: "Berat Kosong",
        dataIndex: "vehicle_empty_weight",
        //  width: 200
      },
      {
        title: "Tanggal Registrasi",
        dataIndex: "created_at",
        //  width: 200,
        key: "created_at",
        render: (text) => {
          const date = new Date(text);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        },
      },
    ];
  }

  const tombol = [
    {
      title: "Transaksi BSU",
      icon: money,
      icons: moneyW,
      link: "transactions",
    },
    {
      title: "Transaksi TPA",
      icon: money,
      icons: moneyW,
      link: "tpa-transactions",
    },
    {
      title: "Pelapak",
      icon: kons,
      icons: konsW,
      link: "consumers",
    },
    // {
    //   title: "Jenis Sampah",
    //   icon: sampah,
    //   icons: sampahW,
    //   link: "wastes",
    // },
    {
      title: "Kategori",
      icon: kategori,
      icons: kategoriW,
      link: "categories",
    },
    {
      title: "Sub-Kategori",
      icon: sub,
      icons: subW,
      link: "subcategories",
    },
    {
      title: "Pegawai BSU",
      icon: pegawai,
      icons: pegawaiW,
      link: "employees",
    },
    {
      title: "Bank Sampah Induk",
      icon: bank,
      icons: bankW,
      link: "main-banks",
    },
    {
      title: "Bank Sampah Unit",
      icon: unit,
      icons: unitW,
      link: "unit-banks",
    },
    {
      title: "Supplier",
      icon: sup,
      icons: supW,
      link: "suppliers",
    },
    {
      title: "Angkutan",
      icon: truk,
      icons: trukW,
      link: "vehicles",
    },
    {
      title: "Sopir",
      icon: sopir,
      icons: sopirW,
      link: "drivers",
    },
  ];

  const handlerSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlerSearch2 = (e) => {
    setSearch2(e.target.value);
  };

  const changeLinks = (value, title) => {
    setLinks(value);
    setTitle(title);
    setCurrentPage(1);
  };

  useEffect(() => {
    //get data
    if (accToken) {
      const getWaste = async () => {
        if (search || search2) {
          setLoading(false);
        } else {
          setLoading(true);
        }

        try {
          const res = await axios.get(`${url}/${links}?page=${currentPage}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          setLoading(false);
          let data;
          if (links === "unit-banks") {
            const main = await axios.get(`${url}/main-banks`, {
              headers: {
                Authorization: `Bearer ${accToken}`,
                Accept: "application/json",
              },
            });
            setLoading(false);
            data = res.data.data.map((bank) => {
              const mainBank = main.data.data.find(
                (mainBank) => mainBank.id === bank.main_bank_id
              );
              return {
                id: bank.id,
                name: bank.name,
                address: bank.address,
                latitude: bank.latitude,
                longitude: bank.longitude,
                mainBankName: mainBank ? mainBank.name : "",
              };
            });
          } else {
            data = await res.data.data;
          }
          let page, nexts, prev, total, all;

          if (
            links === "wastes" ||
            links === "employees" ||
            links === "main-banks" ||
            links === "unit-banks"
          ) {
            page = await res.data.per_page;
            nexts = await res.data.next_page_url;
            prev = await res.data.prev_page_url;
            total = await res.data.total;
            all = await res.data.last_page;
          } else {
            page = await res.data.meta.per_page;
            nexts = await res.data.links.next;
            prev = await res.data.links.prev;
            total = await res.data.meta.total;
            all = await res.data.meta.last_page;
          }

          setTotalPages(page);
          setNext(nexts);
          setPrev(prev);
          setTotalItems(total);
          setAll(all);

          // Saring data berdasarkan pencarian dan filter jika ada
          let filteredData = data;
          if (search && search2) {
            const startDate = new Date(search);
            const endDate = new Date(search2);

            filteredData = data.filter((item) => {
              const createdAtDate = new Date(item.created_at);
              return createdAtDate >= startDate && createdAtDate <= endDate;
            });
          }
          setfilteredWaste(filteredData);
        } catch (err) {
          setLoading(false);
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getWaste();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history, currentPage, search, search2, links]);

  // Fungsi handlePaginationChange yang diperbarui
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  // const fetchDataFromAPI = async (pageNumber) => {
  //   try {
  //     const response = await axios.get(`${url}/${links}?page=${pageNumber}`, {
  //       headers: {
  //         Authorization: `Bearer ${accToken}`,
  //         Accept: "application/json",
  //       },
  //     });
  //     const data = await response.data.data;
  //     return data;
  //   } catch (error) {
  //     console.error("Gagal mengambil data dari API:", error);
  //     return null;
  //   }
  // };

  // Fungsi untuk mengekspor data ke Excel
  const handleExportToExcel = async () => {
    try {
      let allData = []; // Array untuk menyimpan semua data

      // Lakukan permintaan API untuk setiap halaman dan gabungkan datanya
      for (let currentPage = 1; currentPage <= all; currentPage++) {
        const pageData = filteredWaste;
        allData = allData.concat(pageData); // Gabungkan data dari halaman saat ini ke dalam array allData
      }

      // Mengonversi data gabungan ke format yang dapat digunakan oleh XLSX
      const worksheet = utils.json_to_sheet(allData);

      // Membuat workbook baru dan menambahkan worksheet
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Data");

      // Menyimpan file Excel
      writeFile(workbook, `data_report_${title}.xlsx`);
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };

  const handleExportToPDF = () => {
    const input = document.getElementById("pdf-content");

    // Mendapatkan data dari tabel
    const data = getDataFromTable(input);

    // Membuat instance jsPDF dengan orientasi landscape
    const pdf = new jsPDF("landscape");

    // Menambahkan judul halaman
    pdf.text(`Data ${title}`, 10, 10);

    // Menambahkan tabel ke PDF dengan garis sederhana
    pdf.autoTable({
      startY: 20,
      head: [Object.keys(data[0])], // Menggunakan header dari data
      body: data.map(Object.values), // Menggunakan nilai dari data tanpa header
      theme: "grid", // Menetapkan tema "grid" untuk garis-garis
    });

    // Menyimpan file PDF
    pdf.save(`report_${title}.pdf`);
  };

  const getDataFromTable = (table) => {
    let data = [];
    const rows = table.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const rowData = [];
      const cells = row.querySelectorAll("td, th");

      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j].innerText);
      }

      data.push(rowData);
    }

    return data;
  };

  if (loading) {
    Swal.fire({
      text: "Mohon tunggu...",
      timer: 3000,
    });
  }

  return (
    <div className="w-full flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px]">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
              Halo, Admin
            </h1>
            <p className="text-[#667085] font-normal text-[16px] leading-6">
              Selamat Datang Kembali
            </p>
          </div>
        </div>
        {/* tombol */}
        <div className="mt-10 grid grid-cols-6 gap-3">
          {tombol.map((option) => (
            <button
              onClick={() => changeLinks(option.link, option.title)}
              className={
                links === option.link
                  ? "bg-[#1F7325] border border-[#1F7325] text-white text-sm w-[176px] h-[46px] rounded-md p-4 gap-4 flex flex-row justify-center items-center"
                  : "bg-white border border-[#1F7325] text-[#1F7325] text-sm w-[176px] h-[46px] rounded-md p-4 gap-4 flex flex-row justify-center items-center"
              }
            >
              <img
                src={links === option.link ? option.icons : option.icon}
                alt="search icon"
              />
              {option.title}
            </button>
          ))}
        </div>
        {/* filter */}
        <div>
          <div className="flex flex-row mt-5 gap-[10%]">
            <div className="relative inline-block w-[50%]">
              <input
                className="w-[100%] h-[30px] border-[1px] p-3 text-[12px] rounded-md"
                type="date"
                onChange={handlerSearch}
              />
            </div>
            <div className="relative inline-block w-[50%] mr-6">
              <input
                className="w-[100%] h-[30px] border-[1px] p-3 text-[12px] rounded-md"
                type="date"
                onChange={handlerSearch2}
              />
            </div>
          </div>
        </div>
        {/* export */}
        <div className="flex flex-row gap-6 w-full justify-end items-end mt-5">
          <button
            onClick={handleExportToExcel}
            className="bg-[#1570EF] text-white text-sm w-[136px] h-[46px] rounded-md gap-4 flex flex-row justify-center items-center"
          >
            Export
            <img src={ex} alt="search icon" />
          </button>
          <button
            onClick={handleExportToPDF}
            className="bg-[#7F56D9] text-white text-sm w-[136px] h-[46px] rounded-md gap-4 flex flex-row justify-center items-center"
          >
            Print
            <img src={print} alt="search icon" />
          </button>
        </div>
        {/* Visualisasi Data */}
        <div className="bg-white w-full h-[100%] mt-8 overflow-hidden flex flex-col justify-start items-start py-4 border-[1px] border-[#D0D5DD] rounded-xl">
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "#FFFFFF",
                  borderRadius: "50%",
                  colorBgTextActive: "#00b96b",
                  overflow: "hidden",
                },
                Checkbox: {
                  colorPrimary: "#00b96b",
                },
              },
            }}
          >
            <div
              id="pdf-content"
              className="bg-white w-full h-[100%] overflow-hidden flex flex-col justify-start items-start py-4"
            >
              <h1 className="px-5 border-b-[1px] w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
                Daftar {title}
              </h1>
              <Table
                rowKey="id"
                dataSource={filteredWaste}
                width="1000px"
                className="w-[100%]"
                //  rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{
                  x: 500,
                }}
              />
            </div>
            <Pagination
              className="w-full flex justify-center mt-6"
              current={currentPage}
              total={totalItems}
              pageSize={totalPages}
              onChange={handlePaginationChange}
              showLessItems
              itemRender={(page, type, originalElement) => {
                if (type === "page") {
                  const isActive = page === currentPage;
                  const pageStyle = {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: isActive ? "#1F7325" : "",
                    color: isActive ? "#fff" : "#5F6D7E",
                    borderRadius: "100%",
                  };
                  return (
                    <div
                      style={pageStyle}
                      className={`${isActive ? "active" : ""}`}
                    >
                      {page}
                    </div>
                  );
                }
                if (type === "prev") {
                  return (
                    <p className="text-[#5F6D7E] mr-[100px]">
                      {prev === null ? "" : "Sebelumnya"}
                    </p>
                  );
                }
                if (type === "next") {
                  return (
                    <p className="text-[#5F6D7E] ml-[100px]">
                      {next === null ? "" : "Selanjutnya"}
                    </p>
                  );
                }
                return originalElement;
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default Report;
