// import Comingsoon from './components/comingsoon';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Transaksi from './components/Transaksi';
import Pengguna from './components/Pengguna';
import UserProfile from './components/UserProfile';
import EditProfile from './components/EditProfile';
import Sampah from './components/Sampah';
import AddSampah from './components/AddSampah';
import EditSampah from './components/EditSampah';
import Pegawai from './components/Pegawai';
import AddPegawai from './components/AddPegawai';
import EditPegawai from './components/EditPegawai';
import Bank from './components/bank';
import AddBank from './components/AddBank';
import EditBank from './components/EditBank';
import Unit from './components/Unit';
import AddUnit from './components/AddUnit';
import EditUnit from './components/EditUnit';
import Truk from './components/Truk';
import AddTruk from './components/AddTruk';
import EditTruk from './components/EditTruk';
import Sopir from './components/Sopir';
import AddSopir from './components/AddSopir';
import EditSopir from './components/EditSopir';
import Lacak from './components/Lacak';
import AddLacak from './components/AddLacak';
import LandingPage from './components/LandingPage';
import MainBankScale from './features/main-bank-scale';
import ProcessSorting from './features/main-bank-scale/process-sorting';
import TpaTransaction from './features/tpa-transaction/tpa-transaction';
import Suplier from './components/Suplier';
import AddSuplier from './components/AddSuplier';
import EditSuplier from './components/EditSuplier';
import Kategori from './components/Kategori';
import AddKategori from './components/AddKategori';
import EditKategori from './components/EditKategori';
import Subkategori from './components/Subkategori';
import AddSubkategori from './components/AddSubkategori';
import EditSubkategori from './components/EditSubkategori';
import Customer from './components/Customer';
import AddCustomer from './components/AddCustomer';
import EditCustomer from './components/EditCustomer';
import Kernet from './components/Kernet';
import AddKernet from './components/AddKernet';
import EditKernet from './components/EditKernet';
import Jadwal from './components/Jadwal';
import AddJadwal from './components/AddJadwal';
import EditJadwal from './components/EditJadwal';
import Report from './components/Report';
import News from './components/News';
import AddNews from './components/AddNews';
import EditNews from './components/EditNews';

function App() {
  return (
    <>
      <BrowserRouter>
        <div className='w-full'>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/transaksi' element={<Transaksi />} />
            <Route path='/transaksi-tpa' element={<TpaTransaction />} />
            <Route path='/pengguna' element={<Pengguna />} />
            <Route path='/user-profile' element={<UserProfile />} />
            <Route path='/edit-profile' element={<EditProfile />} />
            <Route path='/sampah' element={<Sampah />} />
            <Route path='/sampah-tambah' element={<AddSampah />} />
            <Route path='/sampah-ubah' element={<EditSampah />} />
            <Route path='/pegawai' element={<Pegawai />} />
            <Route path='/pegawai-tambah' element={<AddPegawai />} />
            <Route path='/pegawai-ubah' element={<EditPegawai />} />
            <Route path='/bank' element={<Bank />} />
            <Route path='/bank-tambah' element={<AddBank />} />
            <Route path='/bank-ubah' element={<EditBank />} />
            <Route path='/unit' element={<Unit />} />
            <Route path='/unit-tambah' element={<AddUnit />} />
            <Route path='/unit-ubah' element={<EditUnit />} />
            <Route path='/truk' element={<Truk />} />
            <Route path='/truk-tambah' element={<AddTruk />} />
            <Route path='/truk-ubah' element={<EditTruk />} />
            <Route path='/sopir' element={<Sopir />} />
            <Route path='/sopir-tambah' element={<AddSopir />} />
            <Route path='/sopir-ubah' element={<EditSopir />} />
            <Route path='/lacak-truk' element={<Lacak />} />
            <Route path='/sopirtruk-tambah' element={<AddLacak />} />
            <Route path='/timbang' element={<MainBankScale />} />
            <Route path='/timbang-proses' element={<ProcessSorting />} />
            <Route path='/suplier' element={<Suplier />} />
            <Route path='/suplier-tambah' element={<AddSuplier />} />
            <Route path='/suplier-ubah' element={<EditSuplier />} />
            <Route path='/kategori' element={<Kategori />} />
            <Route path='/kategori-tambah' element={<AddKategori />} />
            <Route path='/kategori-ubah' element={<EditKategori />} />
            <Route path='/sub' element={<Subkategori />} />
            <Route path='/sub-tambah' element={<AddSubkategori />} />
            <Route path='/sub-ubah' element={<EditSubkategori />} />
            <Route path='/customer' element={<Customer />} />
            <Route path='/customer-tambah' element={<AddCustomer />} />
            <Route path='/customer-ubah' element={<EditCustomer />} />
            <Route path='/kernet' element={<Kernet />} />
            <Route path='/kernet-tambah' element={<AddKernet />} />
            <Route path='/kernet-ubah' element={<EditKernet />} />
            <Route path='/jadwal' element={<Jadwal />} />
            <Route path='/jadwal-tambah' element={<AddJadwal />} />
            <Route path='/jadwal-ubah' element={<EditJadwal />} />
            <Route path='/report' element={<Report />} />
            <Route path='/news' element={<News />} />
            <Route path='/news-tambah' element={<AddNews />} />
            <Route path='/news-ubah' element={<EditNews />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
