import React, { useEffect } from "react";
import { Application } from "@splinetool/runtime";
import Logo from "../assets/logo.png";
import Ps from "../assets/icon-landing/playstore.png";
import Ap from "../assets/icon-landing/apple.png";
import Map from "../assets/MapCard.svg";
import Card from "../assets/card.svg";
import Hero from "../assets/Hero.svg";
import Iphone from "../assets/iphone.svg";
import Saldo from "../assets/saldo.png";
import Misi from "../assets/misi.png";
import Belanja from "../assets/belanja.png";
import Vd from "../assets/video.png";
import Tphone from "../assets/icon-landing/phone.png";
import Mail from "../assets/icon-landing/mail.png";
import Twit from "../assets/icon-landing/twit.png";
import Fb from "../assets/icon-landing/fb.png";
import Ig from "../assets/icon-landing/ig.png";
import Yt from "../assets/icon-landing/yt.png";
import Trash from "../assets/icon-landing/trash.svg";
import Truck from "../assets/icon-landing/truck.svg";
import Recycle from "../assets/icon-landing/recycle.svg";
import A1 from "../assets/profile/1.png";
import A2 from "../assets/profile/2.png";
import A3 from "../assets/profile/3.png";
import A4 from "../assets/profile/4.png";
import A5 from "../assets/profile/5.png";
import A6 from "../assets/profile/6.png";
import A7 from "../assets/profile/7.png";
import A8 from "../assets/profile/8.png";
import A9 from "../assets/profile/9.png";
import A10 from "../assets/profile/10.png";
import A11 from "../assets/profile/11.png";
import { Link } from "react-router-dom";

const LandingPage = () => {
  useEffect(() => {
    const canvas = document.getElementById("canvas3d");
    const app = new Application(canvas);
    app.load("https://prod.spline.design/hOF0atXkIBG222b9/scene.splinecode");
  }, []);

  return (
    <div className="w-screen bg-white overflow-hidden">
      {/* navbar */}
      <div className="z-50 flex justify-between items-start w-screen bg-white px-[6px] md:px-[96px] lg:px-[210px] xl:px-[240px] h-[100px] overflow-hidden">
        <img
          src={Logo}
          alt="indo circular waste"
          className="h-[80px] md:h-[100px] w-auto"
        />
        <div className="relative">
          <Link to="/login">
            <button class="bg-[#1F7325] md:bg-white text-white md:text-[#1F7325] text-sm font-semibold p-3 absolute right-0 md:right-20 xl:right-[90px] w-[121px] h-[44px] rounded-md mt-6">
              Login
            </button>
          </Link>
          <img
            src={Hero}
            alt="indo circular waste"
            className="hidden md:flex w-[300px] md:w-[405px] mr-[60px]"
          />
        </div>
      </div>
      {/* Hero section */}
      <div className="flex flex-col-reverse md:flex-row gap-0 md:gap-28 justify-center items-start w-full px-[10px] md:px-[96px] bg-white">
        <div className="mt-0 md:mt-[32px] bg-white w-full md:w-[50%] lg:px-[200px] xl:px-[300px]">
          <h1 className="mt-10 md:mt-[127px] w-100% md:w-[611px] font-semibold text-[28px] md:text-[48px] text-[#1F1F1F]">
            Ubah Sampah Anda Menjadi Sesuatu yang Menguntungkan
          </h1>
          <div className="mt-[40px] flex justify-start items-center">
            <button className="flex mr-6 justify-center items-center bg-[#1F7325] rounded-[100px] px-8 py-[14px] text-white text-xs md:text-base">
              <img src={Ps} alt="play store" className="h-[20px] w-auto mr-3" />
              Play Store
            </button>
            <button className="flex justify-center items-center bg-[#1F1F1F] rounded-[100px] px-8 py-[14px] text-white text-xs md:text-base">
              <img src={Ap} alt="app store" className="h-[20px] w-auto mr-3" />
              App Store
            </button>
          </div>
        </div>
        <div className="mt-0 w-full md:w-[50%]">
          <div className="-z-10 bg-white overflow-hidden flex justify-center items-center">
            <img
              src={Hero}
              alt="icwaste app"
              className="w-[300px] md:w-[405px] mt-0 md:-mt-[100px]"
            />
          </div>
          <div className="z-40 mt-[900px] md:-mt-[700px] lg:-mt-[1100px] xl:-mt-[1200px] hidden md:flex">
            <canvas id="canvas3d" height="900" className="-ml-[100px]"></canvas>
          </div>
          <div className="z-40 -mt-[350px] justify-center items-center md:-mt-[800px] md:hidden flex">
            <img src={Iphone} alt="iphone" className="" />
          </div>
        </div>
      </div>
      {/* counter */}
      <div className="flex justify-center items-center w-full bg-white ">
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center items-center bg-white gap-0 md:gap-28 mt-[100px] md:-mt-[300px] lg:-mt-[1100px] mb-[80px] lg:mb-0 px-[6px] md:px-[96px]">
          {/* user */}
          <div>
            <div className="w-[350px] md:w-[400px] h-[262px] p-2 md:p-8 rounded-xl md:rounded-2xl flex-col justify-start items-start gap-2 md:gap-2.5 inline-flex overflow-y-scroll overscroll-x-none scroll-smooth ">
              <div className="w-[262px] md:w-[336px] h-[204px] md:h-[900px] relative animate-infinite-scroll">
                <img
                  className="w-[180px] h-[180px] left-0 top-0 absolute rounded-[100px]"
                  src={A1}
                  alt="1"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[90px] absolute rounded-[100px]"
                  src={A2}
                  alt="2"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[810px] absolute rounded-[100px]"
                  src={A3}
                  alt="3"
                />
                <img
                  className="w-[180px] h-[180px] left-0 top-[720px] absolute rounded-[100px]"
                  src={A4}
                  alt="4"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[630px] absolute rounded-[100px]"
                  src={A5}
                  alt="5"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[-90px] absolute rounded-[100px]"
                  src={A6}
                  alt="6"
                />
                <img
                  className="w-[180px] h-[180px] left-0 top-[540px] absolute rounded-[100px]"
                  src={A7}
                  alt="7"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[450px] absolute rounded-[100px]"
                  src={A8}
                  alt="8"
                />
                <img
                  className="w-[180px] h-[180px] left-0 top-[360px] absolute rounded-[100px]"
                  src={A9}
                  alt="9"
                />
                <img
                  className="w-[180px] h-[180px] left-[156px] top-[270px] absolute rounded-[100px]"
                  src={A10}
                  alt="10"
                />
                <img
                  className="w-[180px] h-[180px] left-0 top-[180px] absolute rounded-[100px]"
                  src={A11}
                  alt="11"
                />
              </div>
            </div>
            <div className="self-stretch h-[84px] px-4 py-3 rounded-2xl flex-col justify-start items-start gap-3 flex mb-4 md:mb-0">
              <div className="flex-col justify-start items-start flex">
                <div className="text-neutral-700 textlg md:text-2xl font-semibold font-['Poppins']">
                  7 Ribu Pengguna
                </div>
                <div className="text-gray-500 text-xs md:text-base font-normal font-['Poppins']">
                  Dari seluruh Indonesia
                </div>
              </div>
            </div>
          </div>
          {/* bank */}
          <div>
            <div className="group w-[350px] md:w-[400px] h-[262px] rounded-xl md:rounded-2xl flex-col justify-start items-start overflow-hidden">
              <img
                src={Map}
                alt="indo circular waste"
                className="w-[410px] h-auto relative"
              />
              <img
                src={Card}
                alt="indo circular waste"
                className="-mt-[160px] ml-10 absolute hidden group-hover:flex"
              />
            </div>
            <div className="self-stretch h-[84px] px-4 py-3 rounded-2xl flex-col justify-start items-start gap-3 flex mb-4 md:mb-0">
              <div className="flex-col justify-start items-start flex">
                <div className="text-neutral-700 text-lg md:text-2xl font-semibold font-['Poppins']">
                  200+ Bank Sampah
                </div>
                <div className="text-gray-500 text-xs md:text-base font-normal font-['Poppins']">
                  Tersebar di seluruh Indonesia
                </div>
              </div>
            </div>
          </div>
          {/* sampah */}
          <div>
            <div className="w-[350px] md:w-[400px] h-[262px] rounded-xl md:rounded-2xl flex flex-col justify-center items-center p-8 bg-white ">
              {/* icon load */}
              <div className="flex flex-row justify-between w-[90%] ">
                {/* icon 1 */}
                <div className="flex flex-col w-[45px] justify-center items-center">
                  <div className="mb-10 w-[45px] h-[45px] bg-[#1F7325] rounded-full flex-col justify-center items-start inline-flex">
                    <div className="w-[33px] h-[33px] bg-white rounded-full ml-1.5 flex justify-center items-center">
                      <img src={Trash} alt="trash" className="h-[50px]" />
                    </div>
                  </div>
                </div>
                {/* icon 2 */}
                <div className="flex flex-col w-[45px] justify-center items-center">
                  <div className="mb-10 w-[45px] h-[45px] bg-[#1F7325] rounded-full flex-col justify-center items-start inline-flex">
                    <div className="w-[33px] h-[33px] bg-white rounded-full ml-1.5 flex justify-center items-center">
                      <img src={Truck} alt="truck" className="h-[50px]" />
                    </div>
                  </div>
                </div>
                {/* icon 3 */}
                <div className="flex flex-col w-[45px] justify-center items-center">
                  <div className="mb-10 w-[45px] h-[45px] bg-[#1F7325] rounded-full flex-col justify-center items-start inline-flex">
                    <div className="w-[33px] h-[33px] bg-white rounded-full ml-1.5 flex justify-center items-center">
                      <img src={Recycle} alt="recycle" className="h-[50px]" />
                    </div>
                  </div>
                </div>
              </div>
              {/* load bar */}
              <div className="h-1.5 w-[80%] bg-gray-300 overflow-hidden -mt-3">
                <div className="animate-progress w-full h-full bg-[#1F7325] origin-left-right origin"></div>
              </div>
              {/* node load */}
              <div className="flex flex-row justify-between -mt-3 w-[90%]">
                {/* node 1 */}
                <div className="flex w-[45px] justify-center items-center mt-2">
                  <div className="w-[18px] h-[18px] absolute bg-[#1F7325] rounded-xl flex-col justify-center items-start gap-[8.77px] inline-flex">
                    <div className="w-[10px] h-[10px] bg-white rounded-xl ml-1" />
                  </div>
                </div>
                {/* node 2 */}
                <div className="flex w-[45px] justify-center items-center mt-2">
                  <div className="w-[18px] h-[18px] absolute bg-[#1F7325] rounded-xl flex-col justify-center items-start gap-[8.77px] inline-flex">
                    <div className="w-[10px] h-[10px] bg-white rounded-xl ml-1" />
                  </div>
                </div>
                {/* node 3 */}
                <div className="flex w-[45px] justify-center items-center mt-2">
                  <div className="w-[18px] h-[18px] absolute bg-[#1F7325] rounded-xl flex-col justify-center items-start gap-[8.77px] inline-flex">
                    <div className="w-[10px] h-[10px] bg-white rounded-xl ml-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[84px] px-4 py-3 rounded-2xl flex-col justify-start items-start gap-3 flex mb-4 md:mb-0">
              <div className="flex-col justify-start items-start flex">
                <div className="text-neutral-700 text-lg md:text-2xl font-semibold font-['Poppins']">
                  1 Ton Sampah Tersetor
                </div>
                <div className="text-gray-500 text-xs md:text-base font-normal font-['Poppins']">
                  Dari seluruh Bank Sampah
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* visi misi */}
      {/* <div className='min-h-screen grid grid-cols-2 text-[#1F1F1F] text-center justify-center items-center bg-white w-full px-[96px]'>
            <div className='px-5 py-7 w-[500px] h-[228px] border-[1px] border-[#D9D9D9] rounded-2xl'>
              <h1 className='font-semibold text-[40px] leading-10 mb-2'>
                Visi
              </h1>
              <p className='font-normal text-[18px] leading-[30px] text-[#667085]'>
              Lorem ipsum dolor sit amet consectetur. Ultrices nibh vel etiam varius nisl. Consectetur viverra enim venenatis eleifend aliquam amet. Dui vestibulum eu in facilisis consequat semper et in faucibus.
              </p>
            </div>
            <div className='px-5 py-7 w-[500px] h-[228px] border-[1px] border-[#D9D9D9] rounded-2xl'>
            <h1 className='font-semibold text-[40px] leading-10 mb-2'>
                Misi
              </h1>
              <p className='font-normal text-[18px] leading-[30px] text-[#667085]'>
              Lorem ipsum dolor sit amet consectetur. Ultrices nibh vel etiam varius nisl. Consectetur viverra enim venenatis eleifend aliquam amet. Dui vestibulum eu in facilisis consequat semper et in faucibus.
              </p>
            </div>
        </div> */}
      {/* saldo */}
      <div className="w-full min-h-[563px] justify-center items-center gap-12 md:gap-[179px] p-8 flex flex-col-reverse md:flex-row bg-white mt-0 lg:-mt-[150px]">
        <div className="bg-white">
          <div className="md:w-[400px] text-neutral-700 text-[24px] md:text-[40px] font-semibold">
            Saldo & Top Up
          </div>
          <div className="md:w-[400px] text-gray-500 text-[12px] md:text-[18px] text-base font-normal">
            Simpan uang dan Bayar apa saja dalam satu Aplikasi
          </div>
        </div>
        <div>
          <img src={Saldo} alt="saldo" className="w-[412px]" />
        </div>
      </div>
      {/* Misi */}
      <div className="w-full min-h-[563px] justify-center items-center gap-12 md:gap-[179px] p-8  flex flex-col-reverse md:flex-row-reverse bg-white">
        <div className="bg-white">
          <div className="md:w-[400px] text-neutral-700 text-[24px] md:text-[40px] font-semibold">
            Misi
          </div>
          <div className="md:w-[400px] text-gray-500 text-[12px] md:text-[18px] text-base font-normal">
            Selesaikan misi dan dapatkan Hadiah berupa Saldo
          </div>
        </div>
        <div>
          <img src={Misi} alt="saldo" className="w-[412px]" />
        </div>
      </div>
      {/* belanja */}
      <div className="w-full min-h-[563px] justify-center items-center gap-12 md:gap-[179px] p-8  flex flex-col-reverse md:flex-row bg-white">
        <div className="bg-white">
          <div className="md:w-[400px] text-neutral-700 text-[24px] md:text-[40px] font-semibold">
            Belanja
          </div>
          <div className="md:w-[400px] text-gray-500 text-[12px] md:text-[18px] text-base font-normal">
            Tukar saldomu dengan berbagai barang kebutuhan rumah
          </div>
        </div>
        <div>
          <img src={Belanja} alt="belanja" className="w-[412px]" />
        </div>
      </div>
      {/* video */}
      <div className="p-8 md:p-[96px] bg-white">
        <img src={Vd} alt="video" className="w-full" />
      </div>
      {/* footer */}
      <div className="p-8 md:p-[96px] bg-white">
        <div className="border-t-[1px] border-[#667085] grid grid-cols-1 md:grid-cols-2 py-[27px]">
          <div className="flex flex-col">
            <img
              src={Logo}
              alt="indo circular waste"
              className="h-[59px] md:h-[100px] w-[107px] md:w-[200px] mb-5"
            />
            <div className="flex justify- items-center mt-5">
              <img
                src={Tphone}
                alt="indo circular waste"
                className="h-[13px] md:h-[18px] w-auto mr-3"
              />
              <p className="text-xs md:text-[16px] leading-6 font-medium text-[#1F1F1F]">
                085142526861
              </p>
            </div>
            <div className="flex justify- items-center mt-5">
              <img
                src={Mail}
                alt="indo circular waste"
                className="h-[13px] md:h-[18px] w-auto mr-3"
              />
              <p className="text-xs md:text-[16px] leading-6 font-medium text-[#1F1F1F]">
                indocircularwaste@gmail.com
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center">
            <p className="text-[#1F1F1F] text-xs md:text-[16px] leading-5 mt-[60px] md:mt-0 ml-0 md:ml-2">
              Cari tahu berita terbaru:
            </p>
            <div className="flex flex-row mt-[16px] md:mt-0">
              <img
                src={Twit}
                alt="twitter"
                className="h-[13px] md:h-[20px] ml-0 md:ml-[30px]"
              />
              <img
                src={Fb}
                alt="facebook"
                className="h-[13px] md:h-[20px] ml-[30px]"
              />
              <img
                src={Yt}
                alt="Youtube"
                className="h-[13px] md:h-[20px] ml-[30px]"
              />
              <img
                src={Ig}
                alt="Instagram"
                className="h-[13px] md:h-[20px] ml-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
