import React, { useEffect, useMemo, useState } from "react";
import { Pagination, ConfigProvider, Table } from "antd";
import Sidebar from "./Sidebar";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  // PieChart,
  // Pie,
  // Cell,
} from "recharts";
import axios from "axios";
import Swal from "sweetalert2";
import url from "./BaseUrl";
import { useNavigate } from "react-router-dom";
import SummaryProvider from "../providers/summary-provider";
import Counter from "../widgets/counter/counter";
import dayjs from "dayjs";

const Dashboard = () => {
  const [transaksi, setTransaksi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const [data, setData] = useState();
  const [dataT, setDataT] = useState();
  const accToken = localStorage.getItem("token");
  const history = useNavigate();
  const [tahun, setTahun] = useState("");

  // Cache table column to prevent re-create array over and over again each
  // re-render.
  const columns = useMemo(() => {
    return [
      {
        title: "No",
        dataIndex: "id",
        width: 80,
        fixed: "left",
        render: (_, __, index) => {
          return (currentPage - 1) * totalPages + index + 1;
        },
      },
      {
        title: "Nama Pelanggan",
        dataIndex: "user_name",
        width: 200,
        fixed: "left",
      },
      {
        title: "Nama Bank Sampah Unit",
        dataIndex: "unit_bank_name",
        width: 200,
      },
      {
        title: "Jenis Sampah",
        dataIndex: "subcategory_name",
        width: 100,
      },
      {
        title: "Tanggal",
        dataIndex: "date_of_transaction",
        width: 150,
        key: "date_of_transaction",
        render: (date) => dayjs(date).format("DD/MM/YYYY"),
      },
      {
        title: "Berat",
        width: 100,
        dataIndex: "weight",
        render: (weight) => `${weight} kg`,
      },
      {
        title: "Total Harga",
        width: 100,
        dataIndex: "amount",
      },
    ];
  }, [currentPage, totalPages]);

  useEffect(() => {
    //get data
    if (accToken) {
      const getTransaksi = async () => {
        setLoading(true);
        try {
          const res = await axios.get(
            `${url}/transactions?page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${accToken}`,
                Accept: "application/json",
              },
            }
          );
          setLoading(false);

          // Pagination
          const { per_page, current_page, total } = res.data.meta;

          setTransaksi(res.data.data);
          setTotalPages(per_page);
          setNext(res.data.next_page_url);
          setPrev(res.data.prev_page_url);
          setTotalItems(total);
          setCurrentPage(current_page);
        } catch (err) {
          setLoading(false);
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getTransaksi();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history, currentPage]);

  useEffect(() => {
    const namaBulan = {
      january: "Jan",
      february: "Feb",
      march: "Mar",
      april: "Apr",
      may: "Mei",
      june: "Jun",
      july: "Jul",
      august: "Agu",
      september: "Sep",
      october: "Okt",
      november: "Nov",
      december: "Des",
    };
    const date = new Date();
    const currentYear = date.getFullYear();
    setTahun(currentYear.toString()); // Pastikan bahwa tahun disimpan sebagai string
    const getGraf = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${url}/stats/tpa-trx?year=${currentYear}`,
          {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          }
        );
        if (res.status === 404) {
          // Jika data tidak ditemukan, set nilai default untuk setiap bulan menjadi 0
          const defaultData = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
          };
          setData(
            Object.entries(defaultData).map(([month, value]) => ({
              name: namaBulan[month],
              value,
            }))
          );
        } else {
          setData(
            Object.entries(res.data.data).map(([month, value]) => ({
              name: namaBulan[month],
              value,
            }))
          );
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000,
          });
        }
      }
    };

    getGraf();
  }, [accToken]);

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    setTahun(currentYear.toString()); // Pastikan bahwa tahun disimpan sebagai string
    const getGrafT = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${url}/stats/transactions?year=${currentYear}`,
          {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          }
        );
        setDataT(
          Object.entries(res.data.data).map(([name, value], index) => ({
            name,
            value,
            fill: getColor(index),
          }))
        );
      } catch (err) {
        setLoading(false);
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000,
          });
        }
      }
    };

    getGrafT();
  }, [accToken]);

  const getColor = (index) => {
    const colors = ["#1F7325", "#9747FF", "#F7B01A", "#1570EF", "#F04438"];
    return colors[index % colors.length]; // Melakukan looping warna jika melebihi jumlah warna yang tersedia
  };

  if (loading) {
    Swal.fire({
      text: "Mohon tunggu...",
      timer: 3000,
    });
  }

  // const datas = [
  //   { name: "Industri", value: 700 },
  //   { name: "Pengepul", value: 100 },
  //   { name: "TPS", value: 200 },
  // ];

  // const total = datas.reduce((acc, { value }) => acc + value, 0);

  // const COLORS = ["#1F7325", "#1570EF", "#F04438"];

  const CustomYAxisTick = ({ x, y, payload }) => {
    const value = payload.value;
    const formattedValue = `${value}Kg`;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 rounded-xl border-[1px] border-black bg-white">
          <p className="label">{`${label}`}</p>
          {payload.map((item, index) => (
            <p
              key={index}
              className="value"
            >{`${item.name}: ${item.value} kg`}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Fungsi handlePaginationChange yang diperbarui
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>

        {/* Counter */}
        <SummaryProvider>
          <Counter />
        </SummaryProvider>

        {/* Visualisasi Data 1*/}
        <div className="flex justify-between items-start mt-8 gap-x-2">
          <div className="bg-white w-[100%] h-[550px] overflow-hidden flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className="border-b-[1px] w-full mb-5 text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Jumlah Sampah Tahun {tahun}
            </h1>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis
                    tickFormatter={(value) => `${value} kg`}
                    tick={<CustomYAxisTick />}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <CartesianGrid
                    vertical={false}
                    horizontal={true}
                    strokeDasharray="3 3"
                  />
                  <Bar dataKey="value" fill="#1F7325" radius={[20, 20, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
              {/* <ResponsiveContainer>
                <BarChart
                  className="text-[10px] -ml-[35px]"
                  width={1100}
                  height={400}
                  data={data}
                  aspect={4 / 3}
                >
                  <XAxis dataKey="name" />
                  <YAxis
                    tickFormatter={(value) => `${value} kg`}
                    tick={<CustomYAxisTick />}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="organik" fill="#0C4911" />
                  <Bar dataKey="anorganik" fill="#1F7325" />
                </BarChart>
              </ResponsiveContainer> */}
            </div>
          </div>
          {/* <div className='bg-white w-[40%] h-[550px] overflow-hidden flex flex-col justify-start items-center py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
            <h1 className='border-b-[1px] w-full mb-10 text-[#353535] py-4 leading-6 text-[16px] font-medium'>
              Penjualan Sampah
            </h1>
            <PieChart width={210} height={210}>
              <Pie
                data={datas}
                cx={100}
                cy={100}
                innerRadius={80}
                outerRadius={100}
                fill='#8884d8'
                dataKey='value'
              >
                {datas.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
            <div className='mt-10 w-full'>
              {datas.map((i, index) => (
                <div
                  key={index}
                  className='flex flex-row justify-between items-center py-2 border-b-[1px] last:border-b-[0px] border-[#D0D5DD]'
                >
                  <div className='flex flex-row justify-start items-center'>
                    <div
                      className={`w-3 h-3 rounded mr-[6px] 
                          ${
                            i.name === 'Industri'
                              ? 'bg-[#1F7325]'
                              : i.name === 'Pengepul'
                              ? 'bg-[#1570EF]'
                              : 'bg-[#F04438]'
                          }`}
                    ></div>
                    <p className='font-medium text-[13px] leading-4 text-[#353535]'>
                      {i.name}
                    </p>
                  </div>
                  <div>
                    <p className='font-medium text-[13px] leading-4 text-[#353535]'>
                      {`${((i.value / total) * 100).toFixed(2)}%`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
        {/* Visualisasi Data 2*/}
        <div className="flex justify-between items-start mt-8 gap-x-2">
          <div className="bg-white w-[100%] h-[550px] overflow-hidden flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className="border-b-[1px] w-full mb-5 text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Jumlah Sampah Per Kategori
            </h1>
            <div className="flex flex-row justify-end items-center w-full mb-5">
              <div className="flex flex-row justify-start items-center mr-6">
                <div className="w-3 h-3 rounded bg-[#1F7325] mr-[6px]"></div>
                <p className="font-medium text-[13px] leading-4 text-[#353535]">
                  Plastik
                </p>
              </div>
              <div className="flex flex-row justify-start items-center mr-6">
                <div className="w-3 h-3 rounded bg-[#1570EF] mr-[6px]"></div>
                <p className="font-medium text-[13px] leading-4 text-[#353535]">
                  Kertas
                </p>
              </div>
              <div className="flex flex-row justify-start items-center mr-6">
                <div className="w-3 h-3 rounded bg-[#F7B01A] mr-[6px]"></div>
                <p className="font-medium text-[13px] leading-4 text-[#353535]">
                  Logam
                </p>
              </div>
              <div className="flex flex-row justify-start items-center mr-6">
                <div className="w-3 h-3 rounded bg-[#9747FF] mr-[6px]"></div>
                <p className="font-medium text-[13px] leading-4 text-[#353535]">
                  Kaca
                </p>
              </div>
              <div className="flex flex-row justify-start items-center mr-6">
                <div className="w-3 h-3 rounded bg-[#F04438] mr-[6px]"></div>
                <p className="font-medium text-[13px] leading-4 text-[#353535]">
                  Lainnya
                </p>
              </div>
            </div>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={dataT}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis
                    tickFormatter={(value) => `${value} kg`}
                    tick={<CustomYAxisTick />}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <CartesianGrid
                    vertical={false}
                    horizontal={true}
                    strokeDasharray="3 3"
                  />
                  <Bar dataKey="value" radius={[20, 20, 0, 0]}>
                    {dataT &&
                      dataT.map((entry, index) => (
                        <Bar
                          key={`bar-${index}`}
                          dataKey="value"
                          fill={
                            entry.name === "Plastik"
                              ? "#1F7325"
                              : entry.name === "Kertas"
                              ? "#1570EF"
                              : "#F04438"
                          }
                        />
                      ))}
                  </Bar>
                  {/* <Bar dataKey="value" fill="#1F7325" radius={[20, 20, 0, 0]} /> */}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        {/* Riwayat */}
        <ConfigProvider
          theme={{
            components: {
              Pagination: {
                colorPrimary: "#FFFFFF",
                borderRadius: "50%",
                colorBgTextActive: "#00b96b",
                overflow: "hidden",
              },
              Checkbox: {
                colorPrimary: "#00b96b",
              },
            },
          }}
        >
          <div className="flex justify-between items-center mt-8 gap-x-6">
            <div className="bg-white w-full flex flex-col justify-start items-start py-4 border-[1px] border-[#D0D5DD] rounded-xl">
              <h1 className="px-5 border-b-[1px] w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
                Riwayat
              </h1>
              {/*  <p>
                  {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </p> */}
              <Table
                dataSource={transaksi}
                className="w-full"
                columns={columns}
                pagination={false}
                scroll={{
                  y: 300,
                }}
              />
              <Pagination
                className="w-full flex justify-center mt-6"
                current={currentPage}
                total={totalItems}
                pageSize={totalPages}
                onChange={handlePaginationChange}
                showLessItems
                itemRender={(page, type, originalElement) => {
                  if (type === "page") {
                    const isActive = page === currentPage;
                    const pageStyle = {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      background: isActive ? "#1F7325" : "",
                      color: isActive ? "#fff" : "#5F6D7E",
                      borderRadius: "100%",
                    };
                    return (
                      <div
                        style={pageStyle}
                        className={`${isActive ? "active" : ""}`}
                      >
                        {page}
                      </div>
                    );
                  }
                  if (type === "prev") {
                    return (
                      <p className="text-[#5F6D7E] mr-[100px]">
                        {prev === null ? "" : "Sebelumnya"}
                      </p>
                    );
                  }
                  if (type === "next") {
                    return (
                      <p className="text-[#5F6D7E] ml-[100px]">
                        {next === null ? "" : "Selanjutnya"}
                      </p>
                    );
                  }
                  return originalElement;
                }}
              />
            </div>
          </div>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Dashboard;
