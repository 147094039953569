import React from 'react';
import { useLocation } from 'react-router-dom';

import { p as P } from '@antfu/utils';
import { QRCode } from 'antd';

import Sidebar from '../../components/Sidebar';
import http from '../../utils/http';

function ProcessSorting() {
  const [value, setValue] = React.useState(null);
  const { state } = useLocation();

  React.useEffect(() => {
    const p = P();
    const abortController = new AbortController();

    state.group?.forEach((item) => {
      p.add(http.post('sortings', item, { signal: abortController.signal }));
    });

    (async () => {
      await p;
      setValue(state.uuid);
    })();

    return function cleanUp() {
      p.clear();
      abortController.abort();
    };
  }, [state]);

  return (
    <div className='w-full flex justify-start items-start overflow-hidden'>
      <div className='w-[280px] fixed'>
        <Sidebar />
      </div>
      <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px]'>
        <div className='flex justify-between items-center mb-8'>
          <div>
            <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
              Halo, Admin
            </h1>
            <p className='text-[#667085] font-normal text-[16px] leading-6'>
              Selamat Datang Kembali
            </p>
          </div>
        </div>

        <div className='flex flex-row justify-center items-center'>
          {value && <QRCode type='svg' value={value} />}
        </div>
      </div>
    </div>
  );
}

export default ProcessSorting;
