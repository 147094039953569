import React, { useEffect } from "react";
import { useState } from "react";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const EditCustomer = () => {
  const id = localStorage.getItem("consumers_id");
  const ids = JSON.parse(id);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nik, setNik] = useState("");
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newNik, setNewNik] = useState("");
  const [newPlace, setNewPlace] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newNames, setNewNames] = useState("");
  const [newEmails, setNewEmails] = useState("");
  const [newNiks, setNewNiks] = useState("");
  const [newPlaces, setNewPlaces] = useState("");
  const [newDates, setNewDates] = useState("");
  const [newPhones, setNewPhones] = useState("");
  const [errname, seterrName] = useState("");
  const [erremail, seterrEmail] = useState("");
  const [errnik, seterrNik] = useState("");
  const [errplace, seterrPlace] = useState("");
  const [errdate, seterrDate] = useState("");
  const [errphone, seterrPhone] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgemail, setmsgEmail] = useState("");
  const [msgnik, setmsgNik] = useState("");
  const [msgplace, setmsgPlace] = useState("");
  const [msgdate, setmsgDate] = useState("");
  const [msgphone, setmsgPhone] = useState("");
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  useEffect(() => {
    //get data
    if (accToken) {
      const getPegawai = async () => {
        try {
          const res = await axios.get(`${url}/consumers/${ids}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          setName(data.name);
          setEmail(data.address);
          setPlace(data.city);
          setDate(data.company_phone_number);
          setNik(data.fax);
          setPhone(data.phone);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getPegawai();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, ids, history]);

  //edit pegawai
  const editCustomer = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (newName) {
      formData.append("name", newName);
    }
    if (newEmail) {
      formData.append("address", newEmail);
    }
    if (newNik) {
      formData.append("fax", newNik);
    }
    if (newPhone) {
      formData.append("phone", newPhone);
    }
    if (newPlace) {
      formData.append("city", newPlace);
    }
    if (newDate) {
      formData.append("company_phone_number", newDate);
    }

    axios
      .post(`${url}/consumers/${ids}?_method=PUT`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then(() => {
        Swal.fire({
          title: "Berhasil",
          text: "Data Pelapak berhasil diubah",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/customer");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.address) {
          seterrEmail(true);
          setmsgEmail(msg.address);
        }
        if (msg.city) {
          seterrPlace(true);
          setmsgPlace(msg.city);
        }
        if (msg.company_phone_number) {
          seterrDate(true);
          setmsgDate(msg.company_phone_number);
        }
        if (msg.fax) {
          seterrNik(true);
          setmsgNik(msg.fax);
        }
        if (msg.phone) {
          seterrPhone(true);
          setmsgPhone(msg.phone);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data Pelapak gagal diubah",
          icon: "warning",
        });
      });
  };

  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div className="mb-8">
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>

        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="w-full bg-white flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Ubah Data Pelapak
            </h1>
            <form onSubmit={editCustomer} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Pelapak
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={newNames ? newName : name}
                    onChange={(e) => {
                      setNewName(e.target.value);
                      setNewNames(true);
                    }}
                    placeholder="Nama Pelapak"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Alamat
                </label>
                <span
                  className={
                    erremail ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgemail && msgemail.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="email"
                    value={newEmails ? newEmail : email}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                      setNewEmails(true);
                    }}
                    placeholder="Alamat Pelapak"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Kota
                </label>
                <span
                  className={
                    errplace ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgplace && msgplace.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="place"
                    value={newPlaces ? newPlace : place}
                    onChange={(e) => {
                      setNewPlace(e.target.value);
                      setNewPlaces(true);
                    }}
                    placeholder="Asal Kota Pelapak"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  No. HP
                </label>
                <span
                  className={
                    errphone ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgphone && msgphone.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="phone"
                    value={newPhones ? newPhone : phone}
                    maxLength={13}
                    onChange={(e) => {
                      setNewPhone(e.target.value);
                      setNewPhones(true);
                    }}
                    placeholder="No HP Pelapak"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  No. Telp
                </label>
                <span
                  className={
                    errdate ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgdate && msgdate.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="date"
                    value={newDates ? newDate : date}
                    onChange={(e) => {
                      setNewDate(e.target.value);
                      setNewDates(true);
                    }}
                    placeholder="No. Telp Pelapak"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Fax
                </label>
                <span
                  className={
                    errnik ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgnik && msgnik.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nik"
                    value={newNiks ? newNik : nik}
                    maxLength={16}
                    onChange={(e) => {
                      setNewNik(e.target.value);
                      setNewNiks(true);
                    }}
                    placeholder="Fax"
                  />
                </div>
              </div>
              {/* <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Jenis Kelamin
                        </label>
                        <span className={errgender ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msggender && msggender.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <div>
                            <label>
                              <input className="mt-[6px] font-normal leading-5 p-3 mr-4"
                                type="radio" 
                                value="Laki-Laki" 
                                checked={gender === "Laki-Laki"} 
                                onChange={handleChange} 
                              />
                              <span className='text-[16px] text-[#667085] '> Laki-laki </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input className="mt-[6px] font-normal leading-5 p-3 mr-4" 
                              type="radio" 
                              value="Perempuan" 
                              checked={gender === "Perempuan"} 
                              onChange={handleChange} 
                            />
                              <span className='text-[16px] text-[#667085] '> Perempuan </span>
                            </label>
                          </div>
                        </div>
                      </div> */}

              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
