import axios from "axios";

const token = localStorage.getItem('token');

const http = axios.create({
    baseURL: 'https://gateway.indocircularwaste.co.id/admin',
    headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    },
});

export default http;