import React from 'react'
import Sidebar from './Sidebar'

const UserProfile = () => {
    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-[100%] flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Ubah Pengguna
                    </h1>
                    <form className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nama"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Nama Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Email
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="email"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Alamat
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="alamat"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Alamat Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Tempat Lahir
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="tempat"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Tempat Lahir Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Tanggal Lahir
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="date"
                            id="tanggal"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Tanggal Lahir Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          NIK
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nik"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="NIK Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Jenis Kelamin
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="jk"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Jenis Kelamin Pengguna"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Pekerjaan
                        </label>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="jobs"
                            //value={email}
                            //onChange={(e) => setEmail(e.target.value)}
                            placeholder="Pekerjaan Pengguna"
                          />
                        </div>
                      </div>
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default UserProfile