import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddBank = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [errname, seterrName] = useState("");
  const [erraddress, seterrAddress] = useState("");
  const [errlatitude, seterrLatitude] = useState("");
  const [errlongitude, seterrLongitude] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgaddress, setmsgAddress] = useState("");
  const [msglatitude, setmsgLatitude] = useState("");
  const [msglongitude, setmsgLongitude] = useState("");
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  useEffect(() => {
    //get data
    if (!accToken) {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history]);

  //tambah bank
  const addBank = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    axios
      .post(`${url}/main-banks`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Berhasil",
          text: "Data bank berhasil ditambahkan",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/bank");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.address) {
          seterrAddress(true);
          setmsgAddress(msg.address);
        }
        if (msg.latitude) {
          seterrLatitude(true);
          setmsgLatitude(msg.latitude);
        }
        if (msg.longitude) {
          seterrLongitude(true);
          setmsgLongitude(msg.longitude);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data bank gagal ditambahkan",
          icon: "warning",
        });
      });
  };

  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Tambah Bank Sampah Induk
            </h1>
            <form onSubmit={addBank} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Bank Sampah Induk
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Bank Sampah Induk"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Alamat Bank Sampah Induk
                </label>
                <span
                  className={
                    erraddress ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgaddress && msgaddress.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Alamat Bank Sampah Induk"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Longitude
                </label>
                <span
                  className={
                    errlongitude
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msglongitude && msglongitude.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="longitude"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    placeholder="-7.47056"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Latitude
                </label>
                <span
                  className={
                    errlatitude
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msglatitude && msglatitude.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="latitude"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    placeholder="110.21778"
                  />
                </div>
              </div>
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBank;
