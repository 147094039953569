const formatter = {
  /**
   * Append `KG` suffix to the given string.
   */
  toKG: function (value) {
    return `${value} KG`;
  },
};

const string = { formatter };

export default string;
