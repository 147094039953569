import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";

const EditSopir = () => {
  const id = localStorage.getItem("sopir_id");
  const ids = JSON.parse(id);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");
  const [address, setAddress] = useState("");
  const [nik, setNik] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [rfid, setRfid] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPlace, setNewPlace] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newNik, setNewNik] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newstatus, setnewStatus] = useState("");
  const [newrfid, setnewRfid] = useState("");
  const [newNames, setNewNames] = useState();
  const [newEmails, setNewEmails] = useState();
  const [newPlaces, setNewPlaces] = useState();
  const [newDates, setNewDates] = useState();
  const [newAddresss, setNewAddresss] = useState();
  const [newNiks, setNewNiks] = useState();
  const [newPhones, setNewPhones] = useState();
  const [newstatuss, setnewStatuss] = useState("");
  const [newrfids, setnewRfids] = useState("");
  const [errname, seterrName] = useState("");
  const [erremail, seterrEmail] = useState("");
  const [errplace, seterrPlace] = useState("");
  const [errdate, seterrDate] = useState("");
  const [erraddress, seterrAddress] = useState("");
  const [errnik, seterrNik] = useState("");
  const [errphone, seterrPhone] = useState("");
  const [errstatus, seterrStatus] = useState("");
  const [errrfid, seterrRfid] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgemail, setmsgEmail] = useState("");
  const [msgplace, setmsgPlace] = useState("");
  const [msgdate, setmsgDate] = useState("");
  const [msgaddress, setmsgAddress] = useState("");
  const [msgnik, setmsgNik] = useState("");
  const [msgphone, setmsgPhone] = useState("");
  const [msgstatus, setmsgStatus] = useState("");
  const [msgrfid, setmsgRfid] = useState("");
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  const handleSelec = (value) => {
    setnewStatus(value);
    setnewStatuss(true);
  };

  useEffect(() => {
    //get data
    if (accToken) {
      const getSopir = async () => {
        try {
          const res = await axios.get(`${url}/drivers/${ids}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          setName(data.name);
          setEmail(data.email);
          setDate(data.date_of_birth);
          setPlace(data.place_of_birth);
          setNik(data.nik);
          setPhone(data.phone);
          setAddress(data.address);
          setStatus(data.status);
          setRfid(data.rfid);
        } catch (err) {
          console.log(err);
        }
      };
      getSopir();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, ids, history]);

  console.log(newstatus);
  //update sopir
  const addSopir = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (newName) {
      formData.append("name", newName);
    } else {
      formData.append("name", name);
    }
    if (newEmail) {
      formData.append("email", newEmail);
    } else {
      formData.append("email", email);
    }
    if (newPlace) {
      formData.append("place_of_birth", newPlace);
    } else {
      formData.append("place_of_birth", place);
    }
    if (newDate) {
      formData.append("date_of_birth", newDate);
    } else {
      formData.append("date_of_birth", date);
    }
    if (newAddress) {
      formData.append("address", newAddress);
    } else {
      formData.append("address", address);
    }
    if (newNik) {
      formData.append("nik", newNik);
    } else {
      formData.append("nik", nik);
    }
    if (newPhone) {
      formData.append("phone", newPhone);
    } else {
      formData.append("phone", phone);
    }
    if (newstatus) {
      formData.append("status", newstatus);
    } else {
      formData.append("status", status);
    }
    if (newrfid) {
      formData.append("rfid", newrfid);
    } else {
      formData.append("rfid", rfid);
    }

    axios
      .post(`${url}/drivers/${ids}?_method=PUT`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data Sopir berhasil diubah",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/sopir");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.email) {
          seterrEmail(true);
          setmsgEmail(msg.email);
        }
        if (msg.place_of_birth) {
          seterrPlace(true);
          setmsgPlace(msg.place_of_birth);
        }
        if (msg.date_of_birth) {
          seterrDate(true);
          setmsgDate(msg.date_of_birth);
        }
        if (msg.address) {
          seterrAddress(true);
          setmsgAddress(msg.address);
        }
        if (msg.nik) {
          seterrNik(true);
          setmsgNik(msg.nik);
        }
        if (msg.phone) {
          seterrPhone(true);
          setmsgPhone(msg.phone);
        }
        if (msg.status) {
          seterrStatus(true);
          setmsgStatus(msg.status);
        }
        if (msg.rfid) {
          seterrRfid(true);
          setmsgRfid(msg.rfid);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data Sopir gagal diubah",
          icon: "warning",
        });
      });
  };
  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Ubah Data Sopir
            </h1>
            <form onSubmit={addSopir} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Sopir
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={newNames ? newName : name}
                    onChange={(e) => {
                      setNewName(e.target.value);
                      setNewNames(true);
                    }}
                    placeholder="Nama Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Email
                </label>
                <span
                  className={
                    erremail ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgemail && msgemail.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="email"
                    value={newEmails ? newEmail : email}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                      setNewEmails(true);
                    }}
                    placeholder="Email Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tempat Lahir
                </label>
                <span
                  className={
                    errplace ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgplace && msgplace.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="place"
                    value={newPlaces ? newPlace : place}
                    onChange={(e) => {
                      setNewPlace(e.target.value);
                      setNewPlaces(true);
                    }}
                    placeholder="Tempat Lahir Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tanggal Lahir
                </label>
                <span
                  className={
                    errdate ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgdate && msgdate.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="date"
                    id="date"
                    value={newDates ? newDate : date}
                    onChange={(e) => {
                      setNewDate(e.target.value);
                      setNewDates(true);
                    }}
                    placeholder="yyyy-mm-dd"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Alamat
                </label>
                <span
                  className={
                    erraddress ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgaddress && msgaddress.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="address"
                    value={newAddresss ? newAddress : address}
                    onChange={(e) => {
                      setNewAddress(e.target.value);
                      setNewAddresss(true);
                    }}
                    placeholder="Alamat Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  NIK
                </label>
                <span
                  className={
                    errnik ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgnik && msgnik.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nik"
                    value={newNiks ? newNik : nik}
                    maxLength={16}
                    onChange={(e) => {
                      setNewNik(e.target.value);
                      setNewNiks(true);
                    }}
                    placeholder="NIK Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  No Telepon
                </label>
                <span
                  className={
                    errphone ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgphone && msgphone.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="phone"
                    maxLength={13}
                    value={newPhones ? newPhone : phone}
                    onChange={(e) => {
                      setNewPhone(e.target.value);
                      setNewPhones(true);
                    }}
                    placeholder="No Telepon Sopir"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Status
                </label>
                <span
                  className={
                    errstatus ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgstatus && msgstatus.map((item) => item)}
                </span>
                <div>
                  <Select
                    className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                    onSelect={handleSelec}
                    placeholder="Pilih Status"
                    value={newstatuss ? newstatus : status}
                    optionLabelProp="label"
                  >
                    <Select.Option key="1" value="driver">
                      Driver
                    </Select.Option>
                    <Select.Option key="2" value="co-driver">
                      Co-driver
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  RFID
                </label>
                <span
                  className={
                    errrfid ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgrfid && msgrfid.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="phone"
                    maxLength={13}
                    value={newrfids ? newrfid : rfid}
                    onChange={(e) => {
                      setnewRfid(e.target.value);
                      setnewRfids(true);
                    }}
                    placeholder="Kode RFID Sopir"
                  />
                </div>
              </div>
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSopir;
