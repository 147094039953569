import React, { useEffect } from "react";
import { useState } from "react";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { Select } from "antd";

// const { Option } = Select;

const AddPegawai = () => {
  // const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nik, setNik] = useState("");
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  // const [selectMain, setSelectMain] = useState("");
  const [errname, seterrName] = useState("");
  const [erremail, seterrEmail] = useState("");
  const [errnik, seterrNik] = useState("");
  const [errplace, seterrPlace] = useState("");
  const [errdate, seterrDate] = useState("");
  const [errphone, seterrPhone] = useState("");
  const [errgender, seterrGender] = useState("");
  // const [errbank, seterrBank] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgemail, setmsgEmail] = useState("");
  const [msgnik, setmsgNik] = useState("");
  const [msgplace, setmsgPlace] = useState("");
  const [msgdate, setmsgDate] = useState("");
  const [msgphone, setmsgPhone] = useState("");
  const [msggender, setmsgGender] = useState("");
  // const [msgbank, setmsgBank] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [next, setNext] = useState();
  // const [prev, setPrev] = useState();
  const history = useNavigate();
  const [response, setResponse] = useState("");
  const [resp, setResp] = useState(false);
  const accToken = localStorage.getItem("token");

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page); // Perbarui halaman saat tombol paginasi diklik
  // };

  // const handleSelect = (value) => {
  //   setSelectMain(value);
  // };

  const addSopir = async (e) => {
    e.preventDefault();
    setResp(true);
    const formData = new FormData();
    // formData.append("unit_bank_id", selectMain);
    formData.append("full_name", name);
    formData.append("email", email);
    formData.append("place_of_birth", place);
    formData.append("date_of_birth", date);
    formData.append("gender", gender);
    formData.append("nik", nik);
    formData.append("phone", phone);

    axios
      .post(`${url}/employees`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        const resdata = res.data.data;
        setResponse(resdata);
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.full_name) {
          seterrName(true);
          setmsgName(msg.full_name);
        }
        if (msg.email) {
          seterrEmail(true);
          setmsgEmail(msg.email);
        }
        if (msg.place_of_birth) {
          seterrPlace(true);
          setmsgPlace(msg.place_of_birth);
        }
        if (msg.date_of_birth) {
          seterrDate(true);
          setmsgDate(msg.date_of_birth);
        }
        if (msg.gender) {
          seterrGender(true);
          setmsgGender(msg.gender);
        }
        if (msg.nik) {
          seterrNik(true);
          setmsgNik(msg.nik);
        }
        if (msg.phone) {
          seterrPhone(true);
          setmsgPhone(msg.phone);
        }
        // if (msg.main_bank_id) {
        //   seterrBank(true);
        //   setmsgBank(msg.main_bank_id);
        // }
        Swal.fire({
          title: "Gagal",
          text: "Data pegawai gagal ditambahkan",
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    //get data
    if (accToken) {
      // const getBank = async (page) => {
      //   try {
      //     const res = await axios.get(`${url}/unit-banks?page=${page}`, {
      //       headers: {
      //         Authorization: `Bearer ${accToken}`,
      //         Accept: "application/json",
      //       },
      //     });
      //     const data = await res.data.data;
      //     const next = await res.data.next_page_url;
      //     const prev = await res.data.prev_page_url;
      //     setBank(data);
      //     setNext(next);
      //     setPrev(prev);
      //   } catch (err) {
      //     if (err.response.status === 500) {
      //       Swal.fire({
      //         text: "Server Error",
      //         icon: "warning",
      //         timer: 3000,
      //       });
      //     }
      //   }
      // };
      // getBank(currentPage);
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history]); // currentPage]);

  if (response && resp) {
    Swal.fire({
      title: "Data Pegawai Berhasil Ditambahkan",
      html: `
        <p>Email: ${response.email}</p>
        <p>Kata Sandi: ${response.password}</p>
        <button style="font-weight: bold; color: #FFF; background-color: #1F7325; margin: 15px; border-radius: 10px; padding: 15px;" id="copyButton">Salin Data</button>
      `,
      icon: "success",
      didOpen: () => {
        const copyButton = Swal.getPopup().querySelector("#copyButton");
        copyButton.addEventListener("click", () => {
          const textToCopy = `email: ${response.email}, password: ${response.password}`;
          navigator.clipboard.writeText(textToCopy).then(() => {
            Swal.update({
              title: "Teks Berhasil Di-copy",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });
          });
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        history("/pegawai");
      }
    });
  }

  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div className="mb-8">
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Tambah Data Pegawai
            </h1>
            <form onSubmit={addSopir} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Pegawai
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Pegawai"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Email
                </label>
                <span
                  className={
                    erremail ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgemail && msgemail.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Pegawai"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  NIK
                </label>
                <span
                  className={
                    errnik ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgnik && msgnik.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nik"
                    value={nik}
                    maxLength={16}
                    onChange={(e) => setNik(e.target.value)}
                    placeholder="NIK Pegawai"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tempat Lahir
                </label>
                <span
                  className={
                    errplace ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgplace && msgplace.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="place"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    placeholder="Tempat Lahir Pegawai"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tanggal Lahir
                </label>
                <span
                  className={
                    errdate ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgdate && msgdate.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="yyyy-mm-dd"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Jenis Kelamin
                </label>
                <span
                  className={
                    errgender ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msggender && msggender.map((item) => item)}
                </span>
                <div>
                  <div>
                    <label>
                      <input
                        className="mt-[6px] font-normal leading-5 p-3 mr-4"
                        type="radio"
                        value="Laki-Laki"
                        checked={gender === "Laki-Laki"}
                        onChange={handleChange}
                      />
                      <span className="text-[16px] text-[#667085] ">
                        {" "}
                        Laki-laki{" "}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        className="mt-[6px] font-normal leading-5 p-3 mr-4"
                        type="radio"
                        value="Perempuan"
                        checked={gender === "Perempuan"}
                        onChange={handleChange}
                      />
                      <span className="text-[16px] text-[#667085] ">
                        {" "}
                        Perempuan{" "}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  No Telepon
                </label>
                <span
                  className={
                    errphone ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgphone && msgphone.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="phone"
                    value={phone}
                    maxLength={13}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="No Telepon Pegawai"
                  />
                </div>
              </div>
              {/* <div className="w-full bg-white mt-3">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Bank Sampah Unit
                </label>
                <span
                  className={
                    errbank ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgbank && msgbank.map((item) => item)}
                </span>
                <div>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Pilih Bank Sampah Unit"
                    onSelect={handleSelect}
                    optionLabelProp="label"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        {next !== null || prev !== null ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px",
                            }}
                          >
                            {prev ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className="mr-20"
                              >
                                Sebelumnya
                              </button>
                            ) : null}
                            {next ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                Selanjutnya
                              </button>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  >
                    {bank &&
                      bank.map((item) => (
                        <Option key={item.id} value={item.id} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div> */}
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPegawai;
