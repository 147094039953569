import React from 'react';
import http from '../utils/http';

const contextInitValue = {
  total_weight_of_incoming_waste: 0,
  total_unit_bank: 0,
  total_public_user: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'total-incoming-weight-updated':
      return { ...state, total_weight_of_incoming_waste: action.payload };
    case 'total-unit-bank-updated':
      return { ...state, total_unit_bank: action.payload };
    case 'total-public-user-updated':
      return { ...state, total_public_user: action.payload };
    default:
      return state;
  }
};

export const SummaryContext = React.createContext(contextInitValue);

function SummaryProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, contextInitValue);

  React.useEffect(() => {
    const abortController = new AbortController();

    const token = localStorage.getItem('token');

    if (null !== token) {
      const reqConfig = { signal: abortController.signal };

      http
        .get('stats/stats-summary', reqConfig)
        .then((res) => {
          const data = res.data.data;
          dispatch({
            type: 'total-incoming-weight-updated',
            payload: data.total_weight_of_incoming_waste,
          });
          dispatch({
            type: 'total-unit-bank-updated',
            payload: data.total_unit_bank,
          });
          dispatch({
            type: 'total-public-user-updated',
            payload: data.total_public_user,
          });
        })
        .catch(console.log);
    }

    return function cleanUp() {
      abortController.abort();
    };
  }, []);

  return (
    <SummaryContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SummaryContext.Provider>
  );
}

export default SummaryProvider;
