import React, { useEffect } from "react";
import { useState } from "react";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Select } from "antd";

const EditJadwal = () => {
  const id = localStorage.getItem("jadwaldriver_id");
  const ids = JSON.parse(id);
  // const id2 = localStorage.getItem("jadwalvehicle_id");
  // const ids2 = JSON.parse(id);
  const [sopir, setSopir] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  // const [name, setName] = useState('')
  // const [truck, setTruck] = useState('')
  const [place, setPlace] = useState("");
  const [shift, setShift] = useState("");
  const [errname, seterrName] = useState("");
  const [errtruck, seterrTruck] = useState("");
  const [errplace, seterrPlace] = useState("");
  const [errshift, seterrShift] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgtruck, setmsgTruck] = useState("");
  const [msgplace, setmsgPlace] = useState("");
  const [msgshift, setmsgShift] = useState("");
  const history = useNavigate();
  const accToken = localStorage.getItem("token");
  const [selectSopir, setSelectSopir] = useState("");
  const [selectTruk, setSelectTruk] = useState("");

  /*
    const handleChange = (event) => {
    setGender(event.target.value);
  };
*/

  const handleSelect = (value) => {
    setSelectSopir(value);
  };

  const handleSelects = (value) => {
    setSelectTruk(value);
  };

  useEffect(() => {
    //cek session
    if (accToken) {
      const getJadwal = async () => {
        try {
          const res = await axios.get(`${url}/vehicle-schedule/${ids}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          setSelectSopir(data.driver_nama);
          setSelectTruk(data.vehicle_name);
          setPlace(data.location);
          setShift(data.shift);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getJadwal();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history, ids]);

  const EditJadwal = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("driver_id", selectSopir);
    formData.append("vehicle_id", selectTruk);
    formData.append("place", place);
    formData.append("shift", shift);

    axios
      .post(`${url}/vehicle-schedule`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        const resdata = res.data.data;
        console.log(resdata);
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.driver_id) {
          seterrName(true);
          setmsgName(msg.full_name);
        }
        if (msg.vehicle_id) {
          seterrTruck(true);
          setmsgTruck(msg.nik);
        }
        if (msg.place) {
          seterrPlace(true);
          setmsgPlace(msg.place);
        }
        if (msg.shift) {
          seterrShift(true);
          setmsgShift(msg.shift);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data jadwal gagal ditambahkan",
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    //get data
    if (accToken) {
      const getSopir = async () => {
        try {
          const res = await axios.get(`${url}/drivers`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          setSopir(data);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getSopir();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history]);

  useEffect(() => {
    //get data
    const getTruck = async () => {
      try {
        const res = await axios.get(`${url}/vehicles`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: "application/json",
          },
        });
        const data = await res.data.data;
        setVehicle(data);
      } catch (err) {
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000,
          });
        }
      }
    };
    getTruck();
  }, [accToken]);

  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div className="mb-8">
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Tambah Data Jadwal
            </h1>
            <form onSubmit={EditJadwal} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Pengemudi
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname}
                </span>
                <div>
                  <Select
                    className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                    onSelect={handleSelect}
                    placeholder="Pilih Nama Pengemudi"
                  >
                    {sopir.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Angkutan
                </label>
                <span
                  className={
                    errtruck ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgtruck && msgtruck}
                </span>
                <div>
                  <Select
                    className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                    onSelect={handleSelects}
                    placeholder="Pilih Nama Angkutan"
                  >
                    {vehicle.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tempat Lahir
                </label>
                <span
                  className={
                    errplace ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgplace && msgplace.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="place"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    placeholder="Tempat Lahir"
                  />
                </div>
              </div>

              {/* <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Lokasi
                </label>
                <span
                  className={
                    errplace ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgplace && msgplace.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="lokasi"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    placeholder="Lokasi"
                  />
                </div>
              </div> */}

              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Shift
                </label>
                <span
                  className={
                    errshift ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgshift && msgshift.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="shift"
                    value={shift}
                    onChange={(e) => setShift(e.target.value)}
                    placeholder="Shift"
                  />
                </div>
              </div>

              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJadwal;
