import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar'
import url from './BaseUrl'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddSampah = () => {
  const [name, setName] = useState('')
  const [weight, setWeight] = useState('')
  const [date, setDate] = useState('')
  const [price, setPrice] = useState('')
  const [sell, setSell] = useState('')
  const [limit, setLimit] = useState('')
  const [treat, setTreat] = useState('')
  const [errname, seterrName] = useState('')
  const [errweight, seterrWeight] = useState('')
  const [errdate, seterrDate] = useState('')
  const [errprice, seterrPrice] = useState('')
  const [errsell, seterrSell] = useState('')
  const [errlimit, seterrLimit] = useState('')
  const [errtreat, seterrTreat] = useState('')
  const [msgname, setmsgName] = useState('')
  const [msgweight, setmsgWeight] = useState('')
  const [msgdate, setmsgDate] = useState('')
  const [msgprice, setmsgPrice] = useState('')
  const [msgsell, setmsgSell] = useState('')
  const [msglimit, setmsgLimit] = useState('')
  const [msgtreat, setmsgTreat] = useState('')
  const history = useNavigate()
  const accToken = localStorage.getItem('token')

  const handleChange = (event) => {
    setTreat(event.target.value);
  };

  useEffect(() => {
    //cek session
    if (!accToken) {
      history('/login');
      localStorage.setItem('msg' , 'true')
    }
  }, [accToken, history]);

   //tambah truk
   const AddSampah = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('weight', weight);
    formData.append('date_of_registration', date)
    formData.append('purchase_price', price);
    formData.append('selling_price', sell);
    formData.append('limit_minimal', limit);
    formData.append('treatment', treat)

      axios
        .post(`${url}/wastes`, formData, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            title: "Berhasil",
            text: "Data jenis sampah berhasil ditambahkan",
            icon: "success"
          })
          .then((result) => {
            if (result.isConfirmed) {
              history('/sampah');
            }
          })
        })
        .catch((error) => {
          const msg = error.response.data.errors
          if (msg.name){
            seterrName(true)
            setmsgName(msg.name)
          }
          if (msg.date_of_registration){
            seterrDate(true)
            setmsgDate(msg.date_of_registration)
          }
          if (msg.limit_minimal){
            seterrLimit(true)
            setmsgLimit(msg.limit_minimal)
          }
          if (msg.purchase_price){
            seterrPrice(true)
            setmsgPrice(msg.purchase_price)
          }
          if (msg.treatment){
            seterrTreat(true)
            setmsgTreat(msg.treatment)
          }
          if (msg.selling_price){
            seterrSell(true)
            setmsgSell(msg.selling_price)
          }
          if (msg.weight){
            seterrWeight(true)
            setmsgWeight(msg.weight)
          }
          Swal.fire({
            title: "Gagal",
            text: "Data jenis sampah gagal ditambahkan",
            icon: "warning"
          });
        });
  };
    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Tambah Data jenis Sampah
                    </h1>
                    <form onSubmit={AddSampah} className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama Jenis Sampah
                        </label>
                        <span className={errname ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgname && msgname.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nama"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Jenis Sampah"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Berat
                        </label>
                        <span className={errweight ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgweight && msgweight.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="weight"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            placeholder="... Kg"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Harga Beli
                        </label>
                        <span className={errprice ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgprice && msgprice.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Rp. ... "
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Harga Jual
                        </label>
                        <span className={errsell ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgsell && msgsell.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="sell"
                            value={sell}
                            onChange={(e) => setSell(e.target.value)}
                            placeholder="Rp. ... "
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Berat Minimal
                        </label>
                        <span className={errlimit ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msglimit && msglimit.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="limit"
                            value={limit}
                            onChange={(e) => setLimit(e.target.value)}
                            placeholder="... Kg"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Pengolahan Pada Sampah
                        </label>
                        <span className={errtreat ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgtreat && msgtreat.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <div>
                            <label>
                              <input className="mt-[6px] font-normal leading-5 p-3 mr-4"
                                type="radio" 
                                value="Reuse" 
                                checked={treat === "Reuse"} 
                                onChange={handleChange} 
                              />
                              <span className='text-[16px] text-[#667085] '> Reuse </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input className="mt-[6px] font-normal leading-5 p-3 mr-4" 
                              type="radio" 
                              value="Recycling" 
                              checked={treat === "Recycling"} 
                              onChange={handleChange} 
                            />
                              <span className='text-[16px] text-[#667085] '> Recycling </span>
                            </label>
                          </div>
                          <div>
                            <label>
                              <input className="mt-[6px] font-normal leading-5 p-3 mr-4"
                                type="radio" 
                                value="Burning" 
                                checked={treat === "Burning"} 
                                onChange={handleChange} 
                              />
                              <span className='text-[16px] text-[#667085] '> Burning </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Tanggal Registrasi
                        </label>
                        <span className={errdate ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgdate && msgdate.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="date"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="YYYY-MM-DD"
                          />
                        </div>
                      </div>
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default AddSampah