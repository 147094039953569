import React from 'react';

function SimpleSummary(props) {
  return (
    <div className='bg-white w-[216px] h-[82px] flex justify-start items-center py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
      <div>
        <img src={props.icon} alt={props.name} />
      </div>
      <div className='ml-3 flex flex-col justify-center items-start'>
        <h1 className='font-semibold leading-[30px] text-[22px] text-[#353535]'>
          {props.count}
        </h1>
        <p className='font-medium leading-5 text-[14px] text-[#667085]'>
          {props.name}
        </p>
      </div>
    </div>
  );
}

export default SimpleSummary;
