import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";

const { Option } = Select;

const EditUnit = () => {
  const id = localStorage.getItem("unit_id");
  const ids = JSON.parse(id);
  const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [newName, setNewName] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newLatitude, setNewLatitude] = useState("");
  const [newLongitude, setNewLongitude] = useState("");
  const [newNames, setNewNames] = useState("");
  const [newAddresss, setNewAddresss] = useState("");
  const [newLatitudes, setNewLatitudes] = useState("");
  const [newLongitudes, setNewLongitudes] = useState("");
  const [selectMain, setSelectMain] = useState("");
  const [errbank, seterrBank] = useState("");
  const [errname, seterrName] = useState("");
  const [erraddress, seterrAddress] = useState("");
  const [errlatitude, seterrLatitude] = useState("");
  const [errlongitude, seterrLongitude] = useState("");
  const [msgbank, setmsgBank] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgaddress, setmsgAddress] = useState("");
  const [msglatitude, setmsgLatitude] = useState("");
  const [msglongitude, setmsgLongitude] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  useEffect(() => {
    //get data
    if (accToken) {
      const getBank = async (page) => {
        try {
          const res = await axios.get(`${url}/main-banks?page=${page}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          const next = await res.data.next_page_url;
          const prev = await res.data.prev_page_url;
          setBank(data);
          setNext(next);
          setPrev(prev);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getBank(currentPage);
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history, currentPage]);

  useEffect(() => {
    //get data
    const getUnit = async () => {
      try {
        const res = await axios.get(`${url}/unit-banks/${ids}`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: "application/json",
          },
        });
        const data = await res.data.data;
        setSelectMain(data.main_bank_id);
        setName(data.name);
        setAddress(data.address);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
      } catch (err) {
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000,
          });
        }
      }
    };
    getUnit();
  }, [accToken, ids]);

  const handlePageChange = (page) => {
    setCurrentPage(page); // Perbarui halaman saat tombol paginasi diklik
  };

  const handleSelect = (value) => {
    setSelectMain(value);
  };

  //tambah bank
  const editUnit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("main_bank_id", selectMain);
    if (newName) {
      formData.append("name", newName);
    } else {
      formData.append("name", name);
    }
    if (newAddress) {
      formData.append("address", newAddress);
    } else {
      formData.append("address", address);
    }
    if (newLatitude) {
      formData.append("latitude", newLatitude);
    } else {
      formData.append("latitude", latitude);
    }
    if (newLongitude) {
      formData.append("longitude", newLongitude);
    } else {
      formData.append("longitude", longitude);
    }

    axios
      .post(`${url}/unit-banks/${ids}?_method=PUT`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data bank berhasil diubah",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/unit");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.address) {
          seterrAddress(true);
          setmsgAddress(msg.address);
        }
        if (msg.latitude) {
          seterrLatitude(true);
          setmsgLatitude(msg.latitude);
        }
        if (msg.longitude) {
          seterrLongitude(true);
          setmsgLongitude(msg.longitude);
        }
        if (msg.main_bank_id) {
          seterrBank(true);
          setmsgBank(msg.main_bank_id);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data bank gagal ditambahkan",
          icon: "warning",
        });
      });
  };

  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Ubah Bank Sampah Unit
            </h1>
            <form onSubmit={editUnit} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Bank Sampah Induk
                </label>
                <span
                  className={
                    errbank ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgbank && msgbank.map((item) => item)}
                </span>
                <div>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Pilih Bank Sampah Induk"
                    onSelect={handleSelect}
                    optionLabelProp="label"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        {next !== null || prev !== null ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px",
                            }}
                          >
                            {prev ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className="mr-20"
                              >
                                Sebelumnya
                              </button>
                            ) : null}
                            {next ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                Selanjutnya
                              </button>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  >
                    {bank &&
                      bank.map((item) => (
                        <Option key={item.id} value={item.id} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Bank Sampah Unit
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={newNames ? newName : name}
                    onChange={(e) => {
                      setNewName(e.target.value);
                      setNewNames(true);
                    }}
                    placeholder="Nama Bank Sampah Unit"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Alamat Bank Sampah Unit
                </label>
                <span
                  className={
                    erraddress ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgaddress && msgaddress.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="address"
                    value={newAddresss ? newAddress : address}
                    onChange={(e) => {
                      setNewAddress(e.target.value);
                      setNewAddresss(true);
                    }}
                    placeholder="Alamat Bank Sampah Unit"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Longitude
                </label>
                <span
                  className={
                    errlongitude
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msglongitude && msglongitude.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="longitude"
                    value={newLongitudes ? newLongitude : longitude}
                    onChange={(e) => {
                      setNewLongitude(e.target.value);
                      setNewLongitudes(true);
                    }}
                    placeholder="-7.47056"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Latitude
                </label>
                <span
                  className={
                    errlatitude
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msglatitude && msglatitude.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="latitude"
                    value={newLatitudes ? newLatitude : latitude}
                    onChange={(e) => {
                      setNewLatitude(e.target.value);
                      setNewLatitudes(true);
                    }}
                    placeholder="110.21778"
                  />
                </div>
              </div>
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUnit;
