import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar'
import url from './BaseUrl'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddSuplier = () => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  // const [latitude, setlatitude] = useState('')
  // const [longitude, setlongitude] = useState('')
  const [city, setcity] = useState('')
  // const [type, settype] = useState('')
  const [phone, setPhone] = useState('')
  const [hp, sethp] = useState('')
  const [fax, setFax] = useState('')
  const [errname, seterrName] = useState('')
  const [erraddress, seterrAddress] = useState('')
  // const [errlatitude, seterrlatitude] = useState('')
  // const [errlongitude, seterrlongitude] = useState('')
  const [errcity, seterrcity] = useState('')
  // const [errtype, seterrtype] = useState('')
  const [errphone, seterrPhone] = useState('')
  const [errhp, seterrhp] = useState('')
  const [errfax, seterrFax] = useState('')
  const [msgname, setmsgName] = useState('')
  const [msgaddress, setmsgAddress] = useState('')
  // const [msglatitude, setmsglatitude] = useState('')
  // const [msglongitude, setmsglongitude] = useState('')
  const [msgcity, setmsgcity] = useState('')
  // const [msgtype, setmsgtype] = useState('')
  const [msgphone, setmsgPhone] = useState('')
  const [msghp, setmsghp] = useState('')
  const [msgfax, setmsgFax] = useState('')
  const history = useNavigate()
  const accToken = localStorage.getItem('token')

  useEffect(() => {
    //get data
    if (!accToken) {
      history('/login');
      localStorage.setItem('msg' , 'true')
    }
  }, [accToken, history]);

  //tambah suplier
  const AddSuplier = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    // formData.append('latitude', latitude);
    // formData.append('longitude', longitude);
    // formData.append('type', type);
    formData.append('city', city);
    formData.append('company_phone_number', phone);
    formData.append('fax', fax);
    formData.append('phone', hp);

      axios
        .post(`${url}/suppliers`, formData, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            title: "Berhasil",
            text: "Data supplier berhasil ditambahkan",
            icon: "success"
          })
          .then((result) => {
            if (result.isConfirmed) {
              history('/suplier');
            }
          })
          
        })
        .catch((error) => {
          const msg = error.response.data.errors
          if (msg.name){
            seterrName(true)
            setmsgName(msg.name)
          }
          if (msg.address){
            seterrAddress(true)
            setmsgAddress(msg.address)
          }
          // if (msg.longitude){
          //   seterrlongitude(true)
          //   setmsglongitude(msg.longitude)
          // }
          // if (msg.latitude){
          //   seterrlatitude(true)
          //   setmsglatitude(msg.latitude)
          // }
          // if (msg.type){
          //   seterrtype(true)
          //   setmsgtype(msg.type)
          // }
          if (msg.city){
            seterrcity(true)
            setmsgcity(msg.city)
          }
          if (msg.company_phone_number){
            seterrPhone(true)
            setmsgPhone(msg.company_phone_number)
          }
          if (msg.phone){
            seterrhp(true)
            setmsghp(msg.phone)
          }
          if (msg.fax){
            seterrFax(true)
            setmsgFax(msg.fax)
          }
          Swal.fire({
            title: "Gagal",
            text: "Data supplier gagal ditambahkan",
            icon: "warning"
          });
        });
  };

    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Tambah Supplier
                    </h1>
                    <form onSubmit={AddSuplier} className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama 
                        </label>
                        <span className={errname ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgname && msgname.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nama"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nama Lengkap"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Alamat
                        </label>
                        <span className={erraddress ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgaddress && msgaddress.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Alamat Lengkap"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Kota
                        </label>
                        <span className={errcity ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgcity && msgcity.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="city"
                            value={city}
                            onChange={(e) => setcity(e.target.value)}
                            placeholder="Kota"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          No. Telp
                        </label>
                        <span className={errphone ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgphone && msgphone.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="No. Telp"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Fax
                        </label>
                        <span className={errfax ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgfax && msgfax.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="fax"
                            value={fax}
                            onChange={(e) => setFax(e.target.value)}
                            placeholder="Fax"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          No. HP
                        </label>
                        <span className={errhp ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msghp && msghp.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="hp"
                            value={hp}
                            onChange={(e) => sethp(e.target.value)}
                            placeholder="No. HP"
                          />
                        </div>
                      </div>
                      {/* <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Longitude
                        </label>
                        <span className={errlongitude ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msglongitude && msglongitude.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="longitude"
                            value={longitude}
                            onChange={(e) => setlongitude(e.target.value)}
                            placeholder="longitude"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Latitude
                        </label>
                        <span className={errlatitude ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msglatitude && msglatitude.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="latitude"
                            value={latitude}
                            onChange={(e) => setlatitude(e.target.value)}
                            placeholder="latitude"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Tipe 
                        </label>
                        <span className={errtype ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgtype && msgtype.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="type"
                            value={type}
                            onChange={(e) => settype(e.target.value)}
                            placeholder="Tipe"
                          />
                        </div>
                      </div> */}
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default AddSuplier
