import React, { useEffect, useState } from 'react'
import { Pagination, ConfigProvider, Table, Space, Dropdown, Menu } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import url from './BaseUrl'
import { Select } from 'antd';
import searchIcon from '../assets/icon/search.svg';
import filterIcon from '../assets/icon/filter.svg';

const Kernet = () => {
  // const [kernet, setKernet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0)
  const [next, setNext] = useState()
  const [prev, setPrev] = useState()
  const accToken = localStorage.getItem('token')
  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredCoDriver, setfilteredCoDriver] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const renderActions = (_, record) => (
    <Space size="middle">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => handleEdit(record.id)}>
              Ubah
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(record.id)}>
              Hapus
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined style={{ fontSize: '18px' }} />
      </Dropdown>
    </Space>
  );

    const columns = [
      {
        title: 'No',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        render: (_, __, index) => (currentPage - 1) * totalPages + index + 1,
      },
        {
          title: 'Nama Kernet',
          dataIndex: 'name',
          width: 200, 
          fixed: 'left',
          sorter: (a, b) => a.name.localeCompare(b.name),
          defaultSortOrder: 'ascend',
        },
        {
          title: 'NIK',
          dataIndex: 'nik',
          width: 200,
        },
        {
          title: 'No Telepon',
          dataIndex: 'phone',
          width: 200,
        },
        {
          title: 'Alamat',
          dataIndex: 'address',
          width: 200,
        },
        {
          title: 'Tempat Lahir',
          dataIndex: 'place_of_birth',
          width: 200,
        },
        {
            title: 'Tanggal Lahir',
            dataIndex: 'date_of_birth',
            width: 200,
            key: 'date_of_birth',
            render: (text) => {
              const date = new Date(text);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              const formattedDate = `${day}/${month}/${year}`;
              return formattedDate;
            },
        },
        {
          title: 'Email Pengemudi',
          dataIndex: 'email',
          width: 300,
        },
        {
          title: 'Aksi',
          dataIndex: 'operation',
          key: 'operation',
          width: 100,
          render:renderActions,
        },
      ];

      const handlerSearch = (e) => {
        setSearch(e.target.value);
      };
    
      const handlerFilter = (e) => {
        setFilter(e.target.value);
      };
    
      const handleSelect = (value) => {
        setSelectedColumn(value);
      };

      useEffect(() => {
        //get data
        if (accToken) {
          const getKernet = async () => {
            if (search || selectedColumn || filter) {
              setLoading(false);
            } else { setLoading(true); }
            try {
              const res = await axios.get(`${url}/helpers?page=${currentPage}`, {
                headers: {
                  Authorization: `Bearer ${accToken}`,
                  Accept: 'application/json',
                },
              });
              setLoading(false);
                const data = await res.data.data;
                const page = await res.data.meta.per_page;
                const nexts = await res.data.links.next;
                const prev = await res.data.links.prev;
                const total = await res.data.meta.total;  // Dapatkan totalItems dari respons API
                // setKernet(data);
                setTotalPages(page);
                setNext(nexts);
                setPrev(prev)
                setTotalItems(total);

                // Saring data berdasarkan pencarian dan filter jika ada
              let filteredData = data;
              if (filter) {
                filteredData = data.filter(item =>
                  item[selectedColumn].toString().toLowerCase().includes(filter.toLowerCase())
                ); 
              }
              if (search) {
                filteredData = data.filter(item =>
                  Object.values(item).some(value =>
                    value && value.toString().toLowerCase().includes(search.toLowerCase())
                  )
                );
              }
              setfilteredCoDriver(filteredData);
            } catch (err) {
              setLoading(false);
              if (err.response.status === 500) {
                Swal.fire({
                  text: 'Server Error',
                  icon: 'warning',
                  timer: 3000,
                });
              }
            }
          };
          getKernet();
        } else {
          history('/login');
          localStorage.setItem('msg', 'true');
        }
      }, [accToken, history, currentPage, search, selectedColumn, filter]); 
    
      // Fungsi handlePaginationChange yang diperbarui
      const handlePaginationChange = (page) => {
        setCurrentPage(page);
      };
    
      // Fungsi refresh yang diperbarui
      const refresh = async () => {
        setLoading(true);
        try {
          const res = await axios.get(`${url}/helpers?page=${currentPage}`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: 'application/json',
            },
          });
          setLoading(false);
            const data = await res.data.data;
            const page = await res.data.meta.per_page;
            const nexts = await res.data.links.next;
            const prev = await res.data.links.prev;
            const total = await res.data.meta.total; // Dapatkan totalItems dari respons API
            // setKernet(data);
            setTotalPages(page);
            setNext(nexts);
            setPrev(prev)
            setTotalItems(total);

            // Saring data berdasarkan pencarian dan filter jika ada
            let filteredData = data;
            if (filter) {
              filteredData = data.filter(item =>
                item[selectedColumn].toString().toLowerCase().includes(filter.toLowerCase())
              ); 
            }
            if (search) {
              filteredData = data.filter(item =>
                Object.values(item).some(value =>
                  value && value.toString().toLowerCase().includes(search.toLowerCase())
                )
              );
            }
            setfilteredCoDriver(filteredData);
        } catch (err) {
          setLoading(false);
          if (err.response.status === 500) {
            Swal.fire({
              text: 'Server Error',
              icon: 'warning',
              timer: 3000,
            });
          }
        }
      };

      if (loading) {
        Swal.fire({
          text: "Mohon tunggu...",
          timer: 3000
        });
      }

      const handleEdit = (id) => {
        localStorage.setItem('kernet_id', JSON.stringify(id));
        history('/kernet-ubah');

      };

      const handleDelete = (id) => {
        axios
        .delete(`${url}/helpers/${id}`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then(() => {
          Swal.fire({
            text: `Data Kernet id:${id} Berhasil Dihapus`,
            icon: "info"
          })
          .then((result) => {
            if (result.isConfirmed) {
              refresh()
            }
          })
        })
        .catch((error) => {
          console.log(error.message);
        });
      };

    const toggleFilter = () => {
      setShowFilter(!showFilter);
    };



    return (
        <div className='w-full flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px]'>
              <div className='flex justify-between items-center'>
                <div>
                  <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                    Halo, Admin
                  </h1>
                  <p className='text-[#667085] font-normal text-[16px] leading-6'>
                    Selamat Datang Kembali
                  </p>
                </div>
                <Link to='/Kernet-tambah'>
                  <button className='mt-[26px] p-[8px] text-white leading-6 font-medium text-[14px] w-[169px] h-[40px] bg-[#1F7325] rounded-lg shadow'>
                    Tambah Kernet 
                  </button>
                </Link>
              </div>
              {/* search + filter */}
              <div>
                <div className="flex flex-row mt-5">
                  <div className="relative inline-block w-[80%] mr-6">
                    <input
                      className="w-[100%] h-[30px] border-[1px] p-3 text-[12px] rounded-md"
                      type="text"
                      onChange={handlerSearch}
                      placeholder="Cari"
                    />
                    <img src={searchIcon} alt="search icon" className="absolute right-2 w-5 h-5 -top-1 pt-1 mt-[5px] " />
                  </div>
                  <div className="relative inline-block w-[20%] mr-6">
                    <button className="w-[100%] h-[30px] text-[#82858a] border-[1px] bg-white flex flex-row justify-start items-center px-3 text-[12px] rounded-md mr-6" onClick={toggleFilter}>
                      Filter
                    </button>
                    <img src={filterIcon} alt="search icon" className="absolute right-2 w-5 h-5 -top-1 pt-1 mt-[5px] " />
                  </div>
                </div>
                {showFilter && (
                  <div className='bg-white w-[40%] p-3 rounded-md border-[1px] ml-[60%] mt-4'>
                    <Select 
                      className="text-[12px] w-[48%] text-[#667085] rounded-lg"
                      onSelect={handleSelect}
                      placeholder='Pilih kolom'
                      >
                        {/* Filter hanya untuk kolom 'name' dan 'description' */}
                        {columns
                          .filter(option => 
                            !["id", "icon", "operation"].includes(option.dataIndex)
                          )
                          .map((option) => (
                            <Select.Option key={option.dataIndex} value={option.dataIndex}>
                              {option.title}
                            </Select.Option>
                          ))}
                    </Select>
                    <input
                      className="w-[47%] h-[30px] border-[1px] p-3 text-[12px] rounded-md ml-2"
                      type="text"
                      onChange={handlerFilter}
                      placeholder={selectedColumn}
                    ></input>
                  </div>
                )}
              </div>
              {/* Visualisasi Data */}
                <div className='bg-white w-full h-[100%] mt-8 overflow-hidden flex flex-col justify-start items-start py-4 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <ConfigProvider
                        theme={{
                        components: {
                            Pagination: {
                            colorPrimary: '#FFFFFF',
                            borderRadius:'50%',
                            colorBgTextActive: '#00b96b',
                            overflow: 'hidden',
                            },
                            Checkbox: {
                            colorPrimary: '#00b96b',
                            },
                        },
                        }}
                    >
                      <h1 className='px-5 border-b-[1px] w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                        Daftar Pengemudi
                      </h1>
                     {/*   <p>
                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </p> */}
                            <Table
                                rowKey='id'
                                dataSource={filteredCoDriver}
                                width='1000px'
                                className='w-[100%]'
                              //  rowSelection={rowSelection}
                                columns={columns}
                                pagination={false}
                                scroll={{
                                  x: 500,
                                }}
                                />
                            <Pagination
                              className="w-full flex justify-center mt-6"
                              current={currentPage}
                              total={totalItems}
                              pageSize={totalPages}
                              onChange={handlePaginationChange}
                              showLessItems
                              itemRender={(page, type, originalElement) => {
                                if (type === 'page') {
                                  const isActive = page === currentPage;
                                  const pageStyle = {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    background: isActive ? '#1F7325' : '',
                                    color: isActive ? '#fff' : '#5F6D7E',
                                    borderRadius: '100%',
                                  };
                                  return (
                                    <div style={pageStyle} className={`${isActive ? 'active' : ''}`}>
                                      {page}
                                    </div>
                                  );
                                }
                                if (type === "prev") {
                                  return <p className="text-[#5F6D7E] mr-[100px]">{prev === null ? "" : "Sebelumnya"}</p>;
                                }
                                if (type === "next") {
                                  return <p className="text-[#5F6D7E] ml-[100px]">{next === null ? "" : "Selanjutnya"}</p>;
                                }
                                return originalElement;
                              }}
                            />
                    </ConfigProvider>
                </div>
            </div>
        </div>
      )
}

export default Kernet
