import React, { useState } from "react";
import dash from "../assets/icon/dash-black.svg";
import tmoney from "../assets/icon/tmoney.svg";
import user from "../assets/icon/user.svg";
import truk from "../assets/icon/truk.svg";
import drive from "../assets/icon/driver.svg";
import track from "../assets/icon/tracking.svg";
import bank from "../assets/icon/bank.svg";
import waste from "../assets/icon/waste.svg";
import main from "../assets/icon/main.svg";
import konsumen from "../assets/icon/konsumen.svg";
import out from "../assets/icon/out.svg";
import dashO from "../assets/icon/dash.svg";
import tmoneyO from "../assets/icon/money-white.svg";
import userO from "../assets/icon/user-white.svg";
import trukO from "../assets/icon/truk-white.svg";
import driveO from "../assets/icon/driver-white.svg";
import trackO from "../assets/icon/tracking-white.svg";
import bankO from "../assets/icon/bank-white.svg";
import wasteO from "../assets/icon/waste-white.svg";
import mainO from "../assets/icon/main.svg";
import suplier from "../assets/icon/Supplier.svg";
import jadwal from "../assets/icon/jadwal.png";
import suplierO from "../assets/icon/supplier-white.svg";
import jadwalO from "../assets/icon/jadwal-white.svg";
import kategori from "../assets/icon/Kategori.png";
import kategoriO from "../assets/icon/kategori-white.svg";
// import jenis from "../assets/icon/jenis.svg";
// import jenisO from "../assets/icon/jenis-white.svg";
import report from "../assets/icon/report.svg";
import reportO from "../assets/icon/report-white.svg";
import sub from "../assets/icon/Sub.png";
import subO from "../assets/icon/sub-white.svg";
import konsumenO from "../assets/icon/konsumen-white.svg";
import Logo from "../assets/logo.png";
import news from "../assets/icon/news.svg";
import newsO from "../assets/icon/news-white.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const menuA = [
  {
    name: "Beranda",
    icon: dash,
    ico: dashO,
    link: "/dashboard",
  },
  {
    name: "Pelapak",
    icon: konsumen,
    ico: konsumenO,
    link: "/customer",
    edit: "/customer-ubah",
    add: "/customer-tambah",
  },
  // {
  //   name: "Jenis Sampah",
  //   icon: jenis,
  //   ico: jenisO,
  //   link: "/sampah",
  //   edit: "/sampah-ubah",
  //   add: "/sampah-tambah",
  // },
  {
    name: "Kategori",
    icon: kategori,
    ico: kategoriO,
    link: "/kategori",
    edit: "/kategori-ubah",
    add: "/kategori-tambah",
  },
  {
    name: "Sub-Kategori",
    icon: sub,
    ico: subO,
    link: "/sub",
    edit: "/sub-ubah",
    add: "/sub-tambah",
  },
  {
    name: "Pegawai BSU",
    icon: user,
    ico: userO,
    link: "/pegawai",
    edit: "/pegawai-ubah",
    add: "/pegawai-tambah",
  },
  {
    name: "Bank Sampah Induk",
    icon: main,
    ico: mainO,
    link: "/bank",
    edit: "/bank-ubah",
    add: "/bank-tambah",
  },
  {
    name: "Bank Sampah Unit",
    icon: bank,
    ico: bankO,
    link: "/unit",
    edit: "/unit-ubah",
    add: "/unit-tambah",
  },
  {
    name: "Supplier",
    icon: suplier,
    ico: suplierO,
    link: "/suplier",
    edit: "/suplier-ubah",
    add: "/suplier-tambah",
  },
  {
    name: "Sopir",
    icon: drive,
    ico: driveO,
    link: "/sopir",
    edit: "/sopir-ubah",
    add: "/sopir-tambah",
  },
  {
    name: "Angkutan",
    icon: truk,
    ico: trukO,
    link: "/truk",
    edit: "/truk-ubah",
    add: "/truk-tambah",
  },
  {
    name: "Berita",
    icon: news,
    ico: newsO,
    link: "/news",
    edit: "/news-ubah",
    add: "/news-tambah",
  },
  // {
  //   name: 'Co-driver',
  //   icon: drive,
  //   ico: driveO,
  //   link: '/kernet',
  //   edit: '/kernet-ubah',
  //   add: '/kernet-tambah',
  // },
];

const menuB = [
  {
    name: "Transaksi",
    icon: tmoney,
    ico: tmoneyO,
    link: "/transaksi",
  },
  {
    name: "Transaksi TPA",
    icon: tmoney,
    ico: tmoneyO,
    link: "/transaksi-tpa",
  },
  {
    name: "Timbang",
    icon: waste,
    ico: wasteO,
    link: "/timbang",
    edit: "/timbang-ubah",
    add: "/timbang-tambah",
  },
  {
    name: "Jadwal Angkutan",
    icon: jadwal,
    ico: jadwalO,
    link: "/jadwal",
    edit: "/jadwal-ubah",
    add: "/jadwal-tambah",
  },
  {
    name: "Lacak Angkutan",
    icon: track,
    ico: trackO,
    link: "/lacak-truk",
    add: "/sopirtruk-tambah",
  },
];

const menuC = [
  {
    name: "Report",
    icon: report,
    ico: reportO,
    link: "/report",
    // edit: '/kernet-ubah',
    // add: '/kernet-tambah',
  },
];

const Sidebar = () => {
  const name = window.location.pathname;
  const email = localStorage.getItem("email");
  const [openmenuA, setOpenMenuA] = useState(false);
  const [openmenuB, setOpenMenuB] = useState(false);
  const [openmenuC, setOpenMenuC] = useState(false);
  const history = useNavigate();
  const openmenuAa = localStorage.getItem("openmenuA");
  const openmenuBb = localStorage.getItem("openmenuB");
  const openmenuCc = localStorage.getItem("openmenuC");

  const toggleMenuA = () => {
    setOpenMenuA(!openmenuA); // Mengubah state ketika "Master Data" diklik
    setOpenMenuB(false);
    setOpenMenuC(false);
    localStorage.setItem("openmenuA", !openmenuA);
    localStorage.setItem("openmenuB", false);
    localStorage.setItem("openmenuC", false);
  };

  const toggleMenuB = () => {
    setOpenMenuB(!openmenuB); // Mengubah state ketika "Manajemen" diklik
    setOpenMenuA(false);
    setOpenMenuC(false);
    localStorage.setItem("openmenuA", false);
    localStorage.setItem("openmenuB", !openmenuB);
    localStorage.setItem("openmenuC", false);
  };

  const toggleMenuC = () => {
    setOpenMenuC(!openmenuC); // Mengubah state ketika "Manajemen" diklik
    setOpenMenuA(false);
    setOpenMenuB(false);
    localStorage.setItem("openmenuA", false);
    localStorage.setItem("openmenuC", !openmenuC);
    localStorage.setItem("openmenuB", false);
  };

  const handleLogout = () => {
    // Hapus semua localStorage
    localStorage.clear();

    // Menggunakan useHistory untuk memindahkan pengguna ke halaman /login
    history("/login");
  };

  return (
    <>
      <div className="inset-0 w-[280px] py-7 h-screen flex flex-col justify-between items-start bg-[#1C2534] border-r-[1px] border-r-[#2E3545] overflow-scroll">
        <div className="h-[80%] overflow-scroll">
          <div className="mb-4 flex justify-start items-center px-[28px] text-[#A5ACBA]">
            <div className="w-[160px] p-2">
              <img src={Logo} alt="ICWaste" />
            </div>
          </div>
          <div
            onClick={toggleMenuA}
            className={
              openmenuA
                ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[17px] leading-[22px] bg-[#2E3646]"
                : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[17px] leading-[22px] hover:bg-[#333B48] "
            }
          >
            Master Data
          </div>
          <div
            className={
              openmenuAa === "true" || openmenuA === "true"
                ? "flex flex-col"
                : "hidden"
            }
          >
            {menuA.map((menus) => (
              <Link to={menus.link} key={menus.name}>
                <div
                  className={
                    name === menus.link ||
                    name === menus.add ||
                    name === menus.edit
                      ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[15px] leading-[22px] bg-[#2E3646]"
                      : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[15px] leading-[22px] hover:bg-[#333B48] "
                  }
                >
                  <img
                    className="h-6 w-6 mr-5"
                    src={name === menus.link ? menus.ico : menus.icon}
                    alt={`/${menus.icon}`}
                  />
                  {menus.name}
                </div>
              </Link>
            ))}
          </div>
          <div
            onClick={toggleMenuB}
            className={
              openmenuB
                ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[17px] leading-[22px] bg-[#2E3646]"
                : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[17px] leading-[22px] hover:bg-[#333B48] "
            }
          >
            Manajemen
          </div>
          <div
            className={
              openmenuBb === "true" || openmenuB === "true"
                ? "flex flex-col"
                : "hidden"
            }
          >
            {menuB.map((menus) => (
              <Link to={menus.link} key={menus.name}>
                <div
                  className={
                    name === menus.link ||
                    name === menus.add ||
                    name === menus.edit
                      ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[15px] leading-[22px] bg-[#2E3646]"
                      : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[15px] leading-[22px] hover:bg-[#333B48] "
                  }
                >
                  <img
                    className="h-6 w-6 mr-5"
                    src={name === menus.link ? menus.ico : menus.icon}
                    alt={`/${menus.icon}`}
                  />
                  {menus.name}
                </div>
              </Link>
            ))}
          </div>
          <div
            onClick={toggleMenuC}
            className={
              openmenuB
                ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[17px] leading-[22px] bg-[#2E3646]"
                : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[17px] leading-[22px] hover:bg-[#333B48] "
            }
          >
            Report
          </div>
          <div
            className={
              openmenuCc === "true" || openmenuC === "true"
                ? "flex flex-col"
                : "hidden"
            }
          >
            {menuC.map((menus) => (
              <Link to={menus.link} key={menus.name}>
                <div
                  className={
                    name === menus.link ||
                    name === menus.add ||
                    name === menus.edit
                      ? "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#FFF] font-semibold text-[15px] leading-[22px] bg-[#2E3646]"
                      : "w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[15px] leading-[22px] hover:bg-[#333B48] "
                  }
                >
                  <img
                    className="h-6 w-6 mr-5"
                    src={name === menus.link ? menus.ico : menus.icon}
                    alt={`/${menus.icon}`}
                  />
                  {menus.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="mt-7 mb-5">
          <div className="mx-[14px] px-4 flex justify-start items-center w-[236px] h-[58px] py-2 rounded-md bg-[#2E3646]">
            <div className="w-10 h-[34px] rounded-full flex justify-center items-center bg-white">
              AD
            </div>
            <div className="flex flex-col justify-center items-start w-full ml-3">
              <h1 className="text-[#F9F9F9] leading-6 text-[16px] font-semibold">
                Admin
              </h1>
              <p className="text-[#A5ACBA] leading-5 text-[14px] font-medium w-[156px] overflow-hidden">
                {email}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={handleLogout}
          className="w-[280px] h-[46px] px-[28px] flex justify-start items-center text-[#A5ACBA] font-semibold text-[15px] leading-[22px] hover:bg-[#333B48]"
        >
          <img className="h-6 w-6 mr-5" src={out} alt="/out" />
          Keluar
        </div>
      </div>
    </>
  );
};

export default Sidebar;
