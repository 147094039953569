import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from "antd";
import upload from "../assets/icon/iconupload.svg";

const AddKategori = () => {
  const [name, setName] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [iconFile, setIconFile] = useState("");
  const [errname, seterrName] = useState("");
  const [errdeskripsi, seterrDeskripsi] = useState("");
  const [erricon, seterrIcon] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgicon, setmsgIcon] = useState("");
  const [msgdeskripsi, setmsgDeskripsi] = useState("");
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  const { Dragger } = Upload;
  // Handle file upload change
  const handleFileChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setIconFile(info.fileList[0]); // Save uploaded file to state
    } else if (status === "error") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setIconFile(info.fileList[0]);
    } else if (status === "removed") {
      message.info(`${info.file.name} file removed.`);
      setIconFile(null);
    }
  };

  const beforeUpload = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const isAllowedType = allowedTypes.includes(file.type);
    if (!isAllowedType) {
      message.error("Hanya file PNG dan JPG yang diperbolehkan!");
    }
    return isAllowedType; // Return true if allowed, false otherwise
  };

  useEffect(() => {
    //cek session
    if (!accToken) {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history]);

  //tambah kategori
  const addKategori = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", deskripsi);
    if (iconFile) {
      formData.append("icon", iconFile.originFileObj);
    }

    axios
      .post(`${url}/categories`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Berhasil",
          text: "Data kategori berhasil ditambahkan",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/kategori");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.description) {
          seterrDeskripsi(true);
          setmsgDeskripsi(msg.description);
        }
        if (msg.icon) {
          seterrIcon(true);
          setmsgIcon(msg.icon);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data kategori gagal ditambahkan",
          icon: "warning",
        });
      });
  };
  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Tambah Kategori
            </h1>
            <form onSubmit={addKategori} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Kategori
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Kategori"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Deskripsi
                </label>
                <span
                  className={
                    errdeskripsi
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msgdeskripsi && msgdeskripsi.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="description"
                    value={deskripsi}
                    onChange={(e) => setDeskripsi(e.target.value)}
                    placeholder="deskripsi"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Icon
                </label>
                <span
                  className={
                    erricon ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgicon && msgicon.map((item) => item)}
                </span>
                <div>
                  <Dragger
                    name="file"
                    multiple={false}
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    onChange={handleFileChange}
                    className="upload-container"
                    beforeUpload={beforeUpload}
                  >
                    <p className="ant-upload-drag-icon justify-center items-center flex">
                      <img src={upload} alt="upload" />
                    </p>
                    <p className="ant-upload-text">
                      klik untuk mengunggah atau seret dan lepas
                    </p>
                    <p className="ant-upload-hint">
                      PNG dan JPG (max. 800x400px)
                    </p>
                    <div className="flex justify-center">
                      {iconFile && (
                        <img
                          src={URL.createObjectURL(iconFile.originFileObj)}
                          alt="icon"
                          className="w-24 mt-4"
                        />
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKategori;
