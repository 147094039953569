import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar'
import url from './BaseUrl'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const EditSuplier = () => {
  const id = localStorage.getItem('suplier_id')
  const ids = JSON.parse(id)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [tlp, setTlp] = useState('')
  const [kota, setkota] = useState('')
  const [hp, sethp] = useState('')
  const [fax, setFax] = useState('')
  // const [latitude, setlatitude] = useState('')
  // const [longitude, setlongitude] = useState('')
  // const [type, setType] = useState('')
  const [newname, setnewName] = useState('')
  const [newaddres, setnewAddres] = useState('')
  const [newtlp, setnewTlp] = useState('')
  const [newkota, setnewkota] = useState('')
  const [newhp, setnewhp] = useState('')
  const [newfax, setnewFax] = useState('')
  // const [newlatitude, setnewlatitude] = useState('')
  // const [newlongitude, setnewlongitude] = useState('')
  // const [newtype, setnewtype] = useState('')
  const [newnames, setnewNames] = useState('')
  const [newaddress, setnewAddress] = useState('')
  const [newtlps, setnewTlps] = useState('')
  const [newkotas, setnewkotas] = useState('')
  const [newhps, setnewhps] = useState('')
  const [newfaxs, setnewFaxs] = useState('')
  // const [newlatitudes, setnewlatitudes] = useState('')
  // const [newlongitudes, setnewlongitudes] = useState('')
  // const [newtypes, setnewTypes] = useState('')
  const [errname, seterrName] = useState('')
  const [erraddress, seterrAddress] = useState('')
  const [errtlp, seterrTlp] = useState('')
  const [errkota, seterrkota] = useState('')
  const [errhp, seterrhp] = useState('')
  const [errfax, seterrFax] = useState('')
  // const [errlatitude, seterrlatitude] = useState('')
  // const [errlongitude, seterrlongitude] = useState('')
  // const [errtype, seterrtype] = useState('')
  const [msgname, setmsgName] = useState('')
  const [msgaddress, setmsgAddress] = useState('')
  const [msgtlp, setmsgTlp] = useState('')
  const [msgkota, setmsgkota] = useState('')
  const [msghp, setmsghp] = useState('')
  const [msgfax, setmsgFax] = useState('')
  // const [msglatitude, setmsglatitude] = useState('')
  // const [msglongitude, setmsglongitude] = useState('')
  // const [msgtype, setmsgtype] = useState('')
  const history = useNavigate()
  const accToken = localStorage.getItem('token')

  useEffect(() => {
    //get data
    if (accToken) {
    const getSuplier = async () => {
      try {
        const res = await axios.get(`${url}/suppliers/${ids}`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        });
        const data = await res.data.data;
        setName(data.name)
        setAddress(data.address)
        setTlp(data.company_phone_number)
        setkota(data.city)
        sethp(data.phone)
        setFax(data.fax)
        // setlatitude(data.latitude)
        // setlongitude(data.longitude)
        // setType(data.type)
      } catch (err) {
        console.log(err)
      }
    };
    getSuplier();
  } else {
    history('/login');
    localStorage.setItem('msg' , 'true')
  }
  }, [accToken, ids, history]);

   //update sopir
   const addSuplier = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (newname) {
        formData.append('name', newname);
    }
    if (newtlp) {
        formData.append('company_phone_number', newtlp);
    }
    if (newkota) {
        formData.append('city', newkota);
    }
    if (newfax) {
        formData.append('fax', newfax);
    } 
    if (newaddres) {
        formData.append('address', newaddres);
    }
    if (newhp) {
        formData.append('phone', newhp);
    }
    // if (newlatitude) {
    //   formData.append('latitude', newlatitude);
    // }
    // if (newlongitude) {
    //   formData.append('longitude', newlongitude);
    // }
    // if (newtype) {
    //   formData.append('type', newtype);
    // }

      axios
        .post(`${url}/suppliers/${ids}?_method=PUT`, formData, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          Swal.fire({
            title: "Berhasil",
            text: "Data Supplier berhasil diubah",
            icon: "success"
          })
          .then((result) => {
            if (result.isConfirmed) {
              history('/suplier')
            }
          })
        })
        .catch((error) => {
          const msg = error.response.data.errors
          if (msg.name){
            seterrName(true)
            setmsgName(msg.name)
          }
          if (msg.address){
            seterrAddress(true)
            setmsgAddress(msg.address)
          }
          if (msg.phone){
            seterrTlp(true)
            setmsgTlp(msg.phone)
          }
          if (msg.kota){
            seterrkota(true)
            setmsgkota(msg.kota)
          }
          if (msg.hp){
            seterrhp(true)
            setmsghp(msg.hp)
          }
          if (msg.fax){
            seterrFax(true)
            setmsgFax(msg.fax)
          }
          // if (msg.longitude){
          //   seterrlongitude(true)
          //   setmsglongitude(msg.longitude)
          // }
          // if (msg.latitude){
          //   seterrlatitude(true)
          //   setmsglatitude(msg.latitude)
          // }
          // if (msg.type){
          //   seterrtype(true)
          //   setmsgtype(msg.type)
          // }
          Swal.fire({
            title: "Gagal",
            text: "Data supplier gagal diubah",
            icon: "warning"
          });
        });
  };
    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Ubah Data Supplier
                    </h1>
                    <form onSubmit={addSuplier} className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama
                        </label>
                        <span className={errname ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgname && msgname.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nama"
                            value={newnames ? newname : name}
                            onChange={(e) => {setnewName(e.target.value)
                                setnewNames(true)}}
                            placeholder="Nama Lengkap"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Alamat
                        </label>
                        <span className={erraddress ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgaddress && msgaddress.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="address"
                            value={newaddress ? newaddres : address}
                            onChange={(e) => {setnewAddres(e.target.value)
                                setnewAddress(true)}}
                            placeholder="Alamat Lengkap"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Kota
                        </label>
                        <span className={errkota ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgkota && msgkota.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="kota"
                            value={newkotas ? newkota : kota}
                            onChange={(e) => {setnewkota(e.target.value)
                                setnewkotas(true)}}
                            placeholder="Nama Kota"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          No. Telp
                        </label>
                        <span className={errtlp ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgtlp && msgtlp.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="tlp"
                            value={newtlps ? newtlp : tlp}
                            onChange={(e) => {setnewTlp(e.target.value)
                                setnewTlps(true)}}
                            placeholder="No. Telp"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Fax
                        </label>
                        <span className={errfax ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgfax && msgfax.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nik"
                            value={newfaxs ? newfax : fax}
                            maxLength={16} 
                            onChange={(e) => {setnewFax(e.target.value)
                                setnewFaxs(true)}}
                            placeholder="Fax"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          No. Hp
                        </label>
                        <span className={errhp ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msghp && msghp.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="hp"
                            maxLength={13} 
                            value={newhps ? newhp : hp}
                            onChange={(e) => {setnewhp(e.target.value)
                                setnewhps(true)}}
                            placeholder="Nomor Hp"
                          />
                        </div>
                      </div>
                      {/* <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Latitude
                        </label>
                        <span className={errlatitude ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msglatitude && msglatitude.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="latitude"
                            value={newlatitudes ? newlatitude : latitude}
                            onChange={(e) => {setnewlatitude(e.target.value)
                                setnewlatitudes(true)}}
                            placeholder="Latitude"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Longitude
                        </label>
                        <span className={errlongitude ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msglongitude && msglongitude.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="longitude"
                            value={newlongitudes ? newlongitude : longitude}
                            onChange={(e) => {setnewlongitude(e.target.value)
                                setnewlongitudes(true)}}
                            placeholder="Longitude"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Tipe 
                        </label>
                        <span className={errtype ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgtype && msgtype.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="type"
                            value={newtypes ? newtype : type}
                            onChange={(e) => {setnewtype(e.target.value)
                                    setnewTypes(true)}}
                            placeholder="Tipe"
                          />
                        </div>
                      </div> */}
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default EditSuplier