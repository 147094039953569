import React, { useState } from 'react'
import { Pagination, ConfigProvider, Table, Space, Dropdown } from 'antd'
import Drop from '../assets/icon/drop-button.svg'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom';

const Pengguna = () => {
  const getguruById = async (id) => {
    console.log (id)
  };

    const columns = [
        {
          title: 'ID',
          dataIndex: 'key',
          width: 100,
          fixed: 'left',
        },
        {
          title: 'Nama',
          dataIndex: 'id_user',
          width: 200
        },
        {
          title: 'Email',
          dataIndex: 'tipe_sampah',
          width: 100,
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          width: 150,
        },
        {
          title: 'Tempat Lahir',
          width: 100,
          dataIndex: 'tempat',
        },
        {
          title: 'Tanggal Lahir',
          width: 100,
          dataIndex: 'tanggal',
        },
        {
          title: 'NIK',
          width: 200,
          dataIndex: 'berat',
        },
        {
          title: 'Jenis Kelamin',
          width: 100,
          dataIndex: 'jk',
        },
        {
          title: 'Pekerjaan',
          width: 100,
          dataIndex: 'kerja',
        },
        {
          title: 'Saldo',
          width: 100,
          dataIndex: 'saldo',
        },
        {
          title: 'Aksi',
          dataIndex: 'operation',
          key: 'operation',
          width: 100,
          render: () => (
            <Space size="middle">
              <Dropdown
                menu={{
                 items,
                }}
              >
                <img src={Drop} alt='/drop-down' />
              </Dropdown>
            </Space>
          ),
        },
      ];
      
      const dataa = [
        {
          key: 1,
          id_user: 'Hendra Budianto',
          tipe_sampah: 'hendra@gmail.com',
          alamat: 'jl. Mawar no 98, Magelang',
          tempat: 'Jogja',
          tanggal: '1976-03-28',
          berat: '1637289394477474',
          jk: 'Laki-laki',
          kerja: 'Wiraswasta',
          saldo:'2300000'
        },
        {
          key: 2,
          id_user: 'Alina Zulfikar',
          tipe_sampah: 'alzulfikar@gmail.com',
          alamat: 'Pondok Marun no 08, Magelang',
          tempat: 'Magelang',
          tanggal: '1987-04-08',
          berat: '9988663886737882',
          jk: 'Perempuan',
          kerja: 'CS',
          saldo:'1500000'
        },
        {
          key: 3,
          id_user: 'Maulina',
          tipe_sampah: 'lina@gmail.com',
          alamat: 'Perum Tidar 3 no 02, Magelang',
          tempat: 'Magelang',
          tanggal: '1991-12-13',
          berat: '19937477749992233',
          jk: 'Perempuan',
          kerja: 'PNS',
          saldo:'1000000'
        },
        {
          key: 4,
          id_user: 'Budi Yahya',
          tipe_sampah: 'yahya@gmail.com',
          alamat: 'Bandongan, Magelang',
          tempat: 'Semarang',
          tanggal: '1990-10-04',
          berat: '5546378838939277',
          jk: 'Laki-laki',
          kerja: 'Tentara',
          saldo:'2000000'
        },
        {
          key: 5,
          id_user: 'Isna Savitri',
          tipe_sampah: 'isna@gmail.com',
          alamat: 'jl. Jingga no 03, Magelang',
          tempat: 'Magelang',
          tanggal: '1988-02-12',
          berat: '75839929884747755',
          jk: 'Perempuan',
          kerja: 'Swasta',
          saldo:'2600000'
        },
        {
          key: 6,
          id_user: 106,
          tipe_sampah: 'Anorganik',
          truk: 'Truk C',
          tanggal: '2022-03-30',
          berat: 150,
        },
        {
          key: 7,
          id_user: 107,
          tipe_sampah: 'Organik',
          truk: 'Truk A',
          tanggal: '2022-03-31',
          berat: 450,
        },
        {
          key: 8,
          id_user: 108,
          tipe_sampah: 'Anorganik',
          truk: 'Truk B',
          tanggal: '2022-03-31',
          berat: 900,
        },
        {
          key: 9,
          id_user: 109,
          tipe_sampah: 'Organik',
          truk: 'Truk C',
          tanggal: '2022-04-01',
          berat: 550,
        },
        {
          key: 10,
          id_user: 110,
          tipe_sampah: 'Anorganik',
          truk: 'Truk A',
          tanggal: '2022-04-01',
          berat: 350,
        },
        {
          key: 11,
          id_user: 111,
          tipe_sampah: 'Organik',
          truk: 'Truk B',
          tanggal: '2022-04-02',
          berat: 800,
        },
        {
          key: 12,
          id_user: 112,
          tipe_sampah: 'Anorganik',
          truk: 'Truk C',
          tanggal: '2022-04-02',
          berat: 1000,
        },
      ]
      
      const items = [
        {
          key: '1',
          label: (
            //<Link to='/edit-profile'>
              <p onClick={() => getguruById(dataa.key)}>
                Ubah
              </p>
            //</Link>
          ),
        },
        {
          key: '2',
          label: (
            <p>
              Hapus
            </p>
          ),
        },
      ];

      const [currentPage, setCurrentPage] = useState(1);
      const [pageSize, setPageSize] = useState(5);
      
        const handleChangePage = (page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        };
      
      
        const paginatedData = dataa.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        );
      
      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      
      // rowSelection object indicates the need for row selection
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedRowKeys(selectedRows);
        }
      };

      console.log(selectedRowKeys)


    return (
        <div className='w-full flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px]'>
              <div className='flex justify-between items-center'>
                <div>
                  <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                    Halo, Admin
                  </h1>
                  <p className='text-[#667085] font-normal text-[16px] leading-6'>
                    Selamat Datang Kembali
                  </p>
                </div>
                <Link to='/user-profile'>
                  <button className='mt-[26px] p-[8px] text-white leading-6 font-medium text-[14px] w-[169px] h-[40px] bg-[#1F7325] rounded-lg shadow'>
                    Tambah Pengguna 
                  </button>
                </Link>
              </div>
              {/* Visualisasi Data */}
                <div className='bg-white w-full h-[100%] mt-8 overflow-hidden flex flex-col justify-start items-start py-4 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <ConfigProvider
                        theme={{
                        components: {
                            Pagination: {
                            colorPrimary: '#FFFFFF',
                            borderRadius:'50%',
                            colorBgTextActive: '#00b96b',
                            overflow: 'hidden',
                            },
                            Checkbox: {
                            colorPrimary: '#00b96b',
                            },
                        },
                        }}
                    >
                      <h1 className='px-5 border-b-[1px] w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                        Daftar Pengguna
                      </h1>
                     {/*   <p>
                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </p> */}
                            <Table
                                dataSource={paginatedData}
                                width='1000px'
                                className='w-[100%]'
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={false}
                                scroll={{
                                  x: 500,
                                }}
                                />
                            <Pagination
                                className="w-full flex justify-center mt-6"
                                current={currentPage}
                                pageSize={pageSize}
                                total={dataa.length}
                                onChange={handleChangePage}
                                //showSizeChanger={false}
                                itemRender={(page, type, originalElement) => {
                                    if (type === 'page') {
                                    const isActive = page === currentPage;
                                    const pageStyle = {
                                        display: 'flex',
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        cursor: 'pointer',
                                        background: isActive ? '#1F7325' : '',
                                        color: isActive ? '#fff' : '#5F6D7E',
                                        borderRadius: '100%',
                                    };
                                    return (
                                        <div style={pageStyle} className={`${isActive ? 'active' : ''}`}>
                                        {page}
                                        </div>
                                    );
                                    }
                                    if (type === "prev") {
                                    return <p className="text-[#5F6D7E] mr-[100px]">Sebelumnya</p>;
                                    }
                                    if (type === "next") {
                                    return <p className="text-[#5F6D7E] ml-[100px]">Selanjutnya</p>;
                                    }
                                return originalElement;
                                }}
                            />
                    </ConfigProvider>
                </div>
            </div>
        </div>
      )
}

export default Pengguna