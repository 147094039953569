import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar'
import url from './BaseUrl'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const EditTruk = () => {
  const id = localStorage.getItem('truk_id')
  const ids = JSON.parse(id)
  const [name, setName] = useState('')
  const [plat, setPlat] = useState('')
  const [deskripsi, setDeskripsi] = useState('')
  const [weight, setWeight] = useState('')
  const [newName, setNewName] = useState();
  const [newPlat, setNewPlat] = useState();
  const [newDeskripsi, setnewDeskripsi] = useState('')
  const [newWeight, setnewWeight] = useState('');
  const [newNames, setNewNames] = useState();
  const [newPlats, setNewPlats] = useState();
  const [newDeskripsis, setnewDeskripsis] = useState('')
  const [newWeights, setnewWeights] = useState('');
  const [errName, seterrName] = useState('')
  const [errPlat, seterrPlat] = useState('')
  const [errDeskripsi, seterrDeskripsi] = useState('')
  const [errWeight, seterrWeight] = useState('')
  const [msgName, setmsgName] = useState('')
  const [msgPlat, setmsgPlat] = useState('')
  const [msgDeskripsi, setmsgDeskripsi] = useState('')
  const [msgWeight, setmsgWeight] = useState('')
  const history = useNavigate()
  const accToken = localStorage.getItem('token')

  useEffect(() => {
    //get data
    if (accToken) {
    const getTruck = async () => {
      try {
        const res = await axios.get(`${url}/vehicles/${ids}`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        });
        const data = await res.data.data;
        setName(data.name)
        setPlat(data.plat_number)
        setDeskripsi(data.description)
        setWeight(data.vehicle_empty_weight)
      } catch (err) {
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000
          });
        }
      }
    };
    getTruck();
  } else {
    history('/login');
    localStorage.setItem('msg' , 'true')
  }
  }, [accToken, ids, history]);

   //edit truk
   const editTruk = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (newName) {
        formData.append('name', newName);
    } else {
      formData.append('name', name);
    }
    if (newPlat) {
        formData.append('plat_number', newPlat);
    } else {
      formData.append('plat_number', plat);
    }
    if (newDeskripsi) {
      formData.append('description', newDeskripsi);
    } else {
      formData.append('description', deskripsi);
    }
    if (newWeight) {
      formData.append('vehicle_empty_weight', newWeight);
    } else {
      formData.append('vehicle_empty_weight', weight);
    }

      axios
        .post(`${url}/vehicles/${ids}?_method=PUT`, formData, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            title: "Berhasil",
            text: "Data angkutan berhasil diubah",
            icon: "success",
            confirmButtonText: 'OK',
          })
          .then ((result) => {
            if (result.isConfirmed) {
             history('/truk');
            }
          })
        })
        .catch((error) => {
          const msg = error.response.data.errors
          if (msg.name){
            seterrName(true)
            setmsgName(msg.name)
          }
          if (msg.plat_number){
            seterrPlat(true)
            setmsgPlat(msg.plat_number)
          }
          if (msg.description){
            seterrDeskripsi(true)
            setmsgDeskripsi(msg.description)
          }
          if (msg.vehicle_empty_weight){
            seterrWeight(true)
            setmsgWeight(msg.vehicle_empty_weight)
          }
          Swal.fire({
            title: "Gagal",
            text: "Data angkutan gagal diubah",
            icon: "warning"
          });
        });
  };
    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Ubah Data Angkutan
                    </h1>
                    <form onSubmit={editTruk} className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama Angkutan
                        </label>
                        <span className={errName ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgName && msgName.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="nama"
                            value={newNames ? newName : name}
                            onChange={(e) => {setNewName(e.target.value)
                                setNewNames(true)}}
                            placeholder="Nama Truk"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Plat Nomor
                        </label>
                        <span className={errPlat ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgPlat && msgPlat.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="plat"
                            value={newPlats ? newPlat : plat}
                            onChange={(e) => {setNewPlat(e.target.value)
                                setNewPlats(true)}}
                            placeholder="Plat Nomor Truk"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white mt-[10px]'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Deskripsi
                        </label>
                        <span className={errDeskripsi ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgDeskripsi && msgDeskripsi.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="text"
                            id="plat"
                            value={newDeskripsis ? newDeskripsi : deskripsi}
                            onChange={(e) => {setnewDeskripsi(e.target.value)
                                setnewDeskripsis(true)}}
                            placeholder="deskripsi"
                          />
                        </div>
                      </div>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Berat Kosong
                        </label>
                        <span className={errWeight ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgWeight && msgWeight.map((item) => (
                            item
                          ))}
                        </span>
                        <div>
                          <input
                            className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                            type="number"
                            id="weight"
                            value={newWeights ? newWeight : weight}
                            onChange={(e) => {setnewWeight(e.target.value)
                                setnewWeights(true)}}
                            placeholder="Berat Kosong"
                          />
                        </div>
                      </div>
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default EditTruk