import React, { useState } from 'react'
import axios from 'axios';
import url from './BaseUrl'
import Loginbg from '../assets/Login-bg.png'
import Swal from 'sweetalert2';
import Logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState('');
    const device = 'google chrome';
    const history = useNavigate();
    const msg = localStorage.getItem('msg')

    //loginHandler
  const loginHandler = async (e) => {
    e.preventDefault();
    localStorage.setItem('remember', remember);
    localStorage.setItem('email', remember ? email : '');

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('device_name', device);

      axios
        .post(`${url}/login`, formData, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => {
          console.log(res.data);
          const data = res.data.data;
          localStorage.setItem('email', email);
          localStorage.setItem('token', data.token);
          history('/dashboard');
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Gagal",
            text: "email atau password salah",
            icon: "warning"
          });
        });
  };

  return (
    <>
        <div className='w-full h-screen bg-white flex justify-center items-center'>
            <div className='w-[50%] bg-white flex justify-center items-start'>
                <div className='flex flex-col justify-center items-start'>
                    <div className='w-[200px] p-2'>
                        <img src={Logo} alt="ICWaste" />
                    </div>
                    <h1 className='mt-8 font-semibold text-[36px] leading-[44px] text-[#353535]'>
                        Masuk
                    </h1>
                    <p className='mt-3 font-normal text-[16px] leading-6 text-[#667085]'>
                        Selamat Datang Kembali
                    </p>
                    { msg ? 
                        <div className='rounded-lg border border-red-400 p-6 bg-red-300 mt-5 font-semibold text-[#353535]'>
                            Silahkan masukkan Email dan Password dahulu
                        </div>
                        : null
                    }
                    <form onSubmit={loginHandler}>
                        <div className='mt-8'>
                            <label className="text-[14px] font-medium leading-5 text-[#353535]">
                                Email
                            </label>
                            <div>
                                <input
                                    className="text-[16px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] lowercase bg-white w-[360px] h-[44px] p-3 rounded-lg"
                                    type="text"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="jhondoe@gmail.com"
                                />
                            </div>
                        </div>
                        <div className='mt-5'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                            Kata Sandi
                        </label>
                            <div>
                                <input
                                    className="text-[16px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] lowercase bg-white w-[360px] h-[44px] p-3 rounded-lg"
                                    type={'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="***********"
                                />
                                </div>
                        </div>
                        <div className='flex justify-between w-[360px] h-[44px]'>
                            <p className="flex items-center justify-start w-full mt-2 text-[14px] font-medium leading-5 text-[#344054]">
                                <input
                                className="mr-2 checked:bg-[#1F7325]"
                                type="checkbox"
                                value="true"
                                onChange={(e) => setRemember(e.target.value)}
                                />
                                    Ingat saya
                            </p>
                           {/* <a className="flex items-center justify-end w-full font-medium text-[14px] leading-5 text-[#1F7325]" href="/">
                                Lupa kata sandi
                            </a>  */}
                        </div>
                            <button className='mt-6 px-[18px] py-[10px] text-white leading-6 font-medium text-[16px] w-[360px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                                Masuk
                            </button>
                    </form>
               {/*      <p className='w-[360px] text-center font-normal text-[12px] leading-5 text-[#667085] mt-[32px] '>
                        Tidak punya akun?
                        <a href='//' className='ml-2 text-[12px] font-medium leading-5 text-[#1F7325]'>
                            Daftar disini
                        </a>
                    </p> */}
                </div>
            </div>
            <div className='w-[50%] bg-white'>
                <img src={Loginbg} className='w-full h-screen' alt='login-page' />
            </div>
        </div>
    </>
  )
}

export default Login
