import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar'
import url from './BaseUrl'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'

const AddLacak = () => {
  const [sopir, setSopir] = useState([]);
  const [truck, setTruck] = useState([]);
  const [selectSopir, setSelectSopir] = useState("");
  const [selectTruk, setSelectTruk] = useState("");
  const [errsopir, seterrSopir] = useState('');
  const [errtruck, seterrTruck] = useState('');
  const [msgsopir, setmsgSopir] = useState('');
  const [msgtruck, setmsgTruck] = useState('');
  const history = useNavigate()
  const accToken = localStorage.getItem('token')

  useEffect(() => {
    //get data
    if (accToken) {
      const getSopir = async () => {
        try {
          const res = await axios.get(`${url}/drivers`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: 'application/json',
            },
          });
          const data = await res.data.data;
          setSopir(data);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000
            });
          }
        }
      };
      getSopir();
    } else {
      history('/login')
      localStorage.setItem('msg' , 'true')
    }
  }, [accToken, history]);

  useEffect(() => {
    //get data
    const getTruck = async () => {
      try {
        const res = await axios.get(`${url}/vehicles`, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        });
        const data = await res.data.data;
        setTruck(data);
      } catch (err) {
        if (err.response.status === 500) {
          Swal.fire({
            text: "Server Error",
            icon: "warning",
            timer: 3000
          });
        }
      }
    };
    getTruck();
  }, [accToken]);

  const handleSelect = (value) => {
    setSelectSopir(value);
  };

  const handleSelects = (value) => {
    setSelectTruk(value);
  };

   // tambah sopir
   const addSopir = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('driver_id', selectSopir);
    formData.append('vehicle_id', selectTruk);

      axios
        .post(`${url}/vehicle-schedule`, formData, {
          headers: {
            Authorization: `Bearer ${accToken}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          Swal.fire({
            title: "Berhasil",
            text: "Data jadwal berhasil ditambahkan",
            icon: "success"
          })
          .then((result) => {
            if (result.isConfirmed) {
              history('/lacak-truk');
            }
          })
        })
        .catch((error) => {
          const msg = error.response.data.errors;
          if (msg){
            seterrSopir(true)
            setmsgSopir(msg.driver_id)
          }
          if (msg){
            seterrTruck(true)
            setmsgTruck(msg.vehicle_id)
          }
          console.log(msg)
          Swal.fire({
            title: "Gagal",
            text: "Data jadwal gagal ditambahkan",
            icon: "warning"
          });
        });
  }; 

    return (
        <div className='w-full min-h-screen flex justify-start items-start overflow-hidden'>
            <div className='w-[280px] fixed'>
              <Sidebar />
            </div>
            <div className='w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen'>
              <div>
                <h1 className='text-[#353535] text-[28px] font-semibold leading-9'>
                  Halo, Admin
                </h1>
                <p className='text-[#667085] font-normal text-[16px] leading-6'>
                  Selamat Datang Kembali
                </p>
              </div>
              {/* User */}
              <div className='flex justify-between items-center mt-8 gap-x-6'>
                  <div className='bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl'>
                    <h1 className=' w-full text-[#353535] py-4 leading-6 text-[16px] font-medium'>
                      Tambah Data Pengemudi pada Truk
                    </h1>
                    <form onSubmit={addSopir} className='mt-6 w-full'>
                      <div className='w-full bg-white'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama Pengemudi
                        </label>
                        <span className={errsopir ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgsopir && msgsopir}
                        </span>
                        <div>
                          <Select 
                            className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                            onSelect={handleSelect}
                            placeholder="Pilih Nama Pengemudi"
                          >
                            {sopir.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                {option.name}
                                </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='w-full bg-white mt-8'>
                        <label className="text-[14px] font-medium leading-5 text-[#353535]">
                          Nama Truk
                        </label>
                        <span className={errtruck ? 
                          'mt-1 flex text-[12px] text-red-500' 
                          : 'hidden'}>
                          {msgtruck && msgtruck}
                        </span>
                        <div>
                          <Select 
                            className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                            onSelect={handleSelects}
                            placeholder="Pilih Nama Truk"
                          >
                            {truck.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                {option.name}
                                </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='flex justify-end items-end w-full'>
                        <button className='mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow'>
                          Simpan
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      )
}

export default AddLacak