import React from "react";

import Setor from "../../assets/icon/setor.svg";
import Money from "../../assets/icon/money.svg";
import Unit from "../../assets/icon/unit.svg";
import Nasabah from "../../assets/icon/nasabah.svg";
import SimpleSummary from "../../components/simple-summary";
import { SummaryContext } from "../../providers/summary-provider";
import string from "../../utils/string";

function Counter() {
  const { state } = React.useContext(SummaryContext);

  return (
    <div className="flex justify-between items-center mt-8 gap-x-6">
      <SimpleSummary
        name="Setor Sampah"
        icon={Setor}
        count={string.formatter.toKG(state.total_weight_of_incoming_waste)}
      />
      <SimpleSummary name="Penjualan Sampah" icon={Money} count="0 KG" />
      <SimpleSummary
        name="Bank Sampah Unit"
        icon={Unit}
        count={state.total_unit_bank}
      />
      <SimpleSummary
        name="Nasabah"
        icon={Nasabah}
        count={state.total_public_user}
      />
    </div>
  );
}

export default Counter;
