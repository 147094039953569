import React from "react";
import { useNavigate } from "react-router-dom";

import { v4 } from "uuid";

import Sidebar from "../../components/Sidebar";
import Box from "../../components/box";
import http from "../../utils/http";

// Container to hold sorting values.
const group = [];

function MainBankScale() {
  const navigate = useNavigate();

  const [stats, setStats] = React.useState({
    site_1: 0,
    site_2: 0,
    site_3: 0,
    site_4: 0,
    site_5: 0,
  });
  const [isCalculating, _] = React.useState(false);

  React.useEffect(() => {
    const abortController = new AbortController();

    const reqConfig = { signal: abortController.signal };

    const interval = setInterval(() => {
      http
        .get(
          "https://gateway.indocircularwaste.co.id/scale/conveyor/stats",
          reqConfig
        )
        .then((res) => setStats(res.data.data));
    }, 5 * 1000);

    return function () {
      abortController.abort();
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="w-full flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px]">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
              Halo, Admin
            </h1>
            <p className="text-[#667085] font-normal text-[16px] leading-6">
              Selamat Datang Kembali
            </p>
          </div>
          <button
            className="mt-[26px] p-[8px] text-white leading-6 font-medium text-[14px] w-[169px] h-[40px] bg-[#1F7325] rounded-lg shadow"
            onClick={() => {
              navigate("/timbang-proses", {
                state: { group, uuid: group[0]?.group_id },
              });
              group.length = 0;
            }}
          >
            Lanjut
          </button>
        </div>

        {isCalculating ? (
          <p className="font-medium text-center">Menghitung...</p>
        ) : (
          <div className="grid grid-cols-4 gap-5">
            <Box>
              <h2 className="mb-2 font-medium text-3xl text-center">SITE 1</h2>
              <p className="font-medium text-center text-[#667085]">
                {Number(stats.site_1)} KG
              </p>
            </Box>
            <Box>
              <h2 className="mb-2 font-medium text-3xl text-center">SITE 2</h2>
              <p className="font-medium text-center text-[#667085]">
                {Number(stats.site_2)} KG
              </p>
            </Box>
            <Box>
              <h2 className="mb-2 font-medium text-3xl text-center">SITE 3</h2>
              <p className="font-medium text-center text-[#667085]">
                {Number(stats.site_3)} KG
              </p>
            </Box>
            <Box>
              <h2 className="mb-2 font-medium text-3xl text-center">SITE 4</h2>
              <p className="font-medium text-center text-[#667085]">
                {Number(stats.site_4)} KG
              </p>
            </Box>
            <Box>
              <h2 className="mb-2 font-medium text-3xl text-center">SITE 5</h2>
              <p className="font-medium text-center text-[#667085]">
                {Number(stats.site_5)} KG
              </p>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}

export default MainBankScale;
