import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import url from "./BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Select } from "antd";
import upload from "../assets/icon/iconupload.svg";

const AddSubkategori = () => {
  const [name, setName] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [iconFile, setIconFile] = useState("");
  const [kategori, setKategori] = useState([]);
  const [weight, setWeight] = useState("");
  // const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [sell, setSell] = useState("");
  const [limit, setLimit] = useState("");
  const [treat, setTreat] = useState("");
  const [selectkategori, setSelectKategori] = useState("");
  const [errname, seterrName] = useState("");
  const [errdeskripsi, seterrDeskripsi] = useState("");
  const [erricon, seterrIcon] = useState("");
  const [errkategori, seterrkategori] = useState("");
  const [errweight, seterrWeight] = useState("");
  // const [errdate, seterrDate] = useState("");
  const [errprice, seterrPrice] = useState("");
  const [errsell, seterrSell] = useState("");
  const [errlimit, seterrLimit] = useState("");
  const [errtreat, seterrTreat] = useState("");
  const [msgname, setmsgName] = useState("");
  const [msgicon, setmsgIcon] = useState("");
  const [msgdeskripsi, setmsgDeskripsi] = useState("");
  const [msgkategori, setmsgkategori] = useState("");
  const [msgweight, setmsgWeight] = useState("");
  // const [msgdate, setmsgDate] = useState("");
  const [msgprice, setmsgPrice] = useState("");
  const [msgsell, setmsgSell] = useState("");
  const [msglimit, setmsgLimit] = useState("");
  const [msgtreat, setmsgTreat] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const history = useNavigate();
  const accToken = localStorage.getItem("token");

  const { Dragger } = Upload;
  // Handle file upload change
  const handleFileChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setIconFile(info.fileList[0]); // Save uploaded file to state
    } else if (status === "error") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setIconFile(info.fileList[0]);
    } else if (status === "removed") {
      message.info(`${info.file.name} file removed.`);
      setIconFile(null);
    }
  };

  const beforeUpload = (file) => {
    const allowedTypes = [
      // "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    const isAllowedType = allowedTypes.includes(file.type);
    if (!isAllowedType) {
      message.error("Hanya file PNG dan JPG yang diperbolehkan!");
    }
    return isAllowedType; // Return true if allowed, false otherwise
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // Perbarui halaman saat tombol paginasi diklik
  };

  const handleSelect = (value) => {
    setSelectKategori(value);
  };

  const handleChange = (event) => {
    setTreat(event.target.value);
  };

  useEffect(() => {
    //cek session
    if (accToken) {
      const getKategori = async () => {
        try {
          const res = await axios.get(`${url}/categories`, {
            headers: {
              Authorization: `Bearer ${accToken}`,
              Accept: "application/json",
            },
          });
          const data = await res.data.data;
          const next = await res.data.links.next;
          const prev = await res.data.links.prev;
          setKategori(data);
          setNext(next);
          setPrev(prev);
        } catch (err) {
          if (err.response.status === 500) {
            Swal.fire({
              text: "Server Error",
              icon: "warning",
              timer: 3000,
            });
          }
        }
      };
      getKategori();
    } else {
      history("/login");
      localStorage.setItem("msg", "true");
    }
  }, [accToken, history]);

  //tambah kategori
  const addKategori = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", deskripsi);
    formData.append("category_id", selectkategori);
    if (iconFile) {
      formData.append("icon", iconFile.originFileObj);
    }
    formData.append("weight", weight);
    // formData.append("date_of_registration", date);
    formData.append("purchase_price", price);
    formData.append("selling_price", sell);
    formData.append("limit_minimal", limit);
    formData.append("treatment", treat);

    axios
      .post(`${url}/subcategories`, formData, {
        headers: {
          Authorization: `Bearer ${accToken}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Berhasil",
          text: "Data sub-kategori berhasil ditambahkan",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            history("/sub");
          }
        });
      })
      .catch((error) => {
        const msg = error.response.data.errors;
        if (msg.name) {
          seterrName(true);
          setmsgName(msg.name);
        }
        if (msg.description) {
          seterrDeskripsi(true);
          setmsgDeskripsi(msg.description);
        }
        if (msg.icon) {
          seterrIcon(true);
          setmsgIcon(msg.icon);
        }
        if (msg.category_id) {
          seterrkategori(true);
          setmsgkategori(msg.category_id);
        }
        // if (msg.date_of_registration) {
        //   seterrDate(true);
        //   setmsgDate(msg.date_of_registration);
        // }
        if (msg.limit_minimal) {
          seterrLimit(true);
          setmsgLimit(msg.limit_minimal);
        }
        if (msg.purchase_price) {
          seterrPrice(true);
          setmsgPrice(msg.purchase_price);
        }
        if (msg.treatment) {
          seterrTreat(true);
          setmsgTreat(msg.treatment);
        }
        if (msg.selling_price) {
          seterrSell(true);
          setmsgSell(msg.selling_price);
        }
        if (msg.weight) {
          seterrWeight(true);
          setmsgWeight(msg.weight);
        }
        Swal.fire({
          title: "Gagal",
          text: "Data sub-kategori gagal ditambahkan",
          icon: "warning",
        });
      });
  };
  return (
    <div className="w-full min-h-screen flex justify-start items-start overflow-hidden">
      <div className="w-[280px] fixed">
        <Sidebar />
      </div>
      <div className="w-[calc(100%-280px)] p-8 bg-[#FAFBFC] ml-[280px] min-h-screen">
        <div>
          <h1 className="text-[#353535] text-[28px] font-semibold leading-9">
            Halo, Admin
          </h1>
          <p className="text-[#667085] font-normal text-[16px] leading-6">
            Selamat Datang Kembali
          </p>
        </div>
        {/* User */}
        <div className="flex justify-between items-center mt-8 gap-x-6">
          <div className="bg-white w-full flex flex-col justify-start items-start py-4 px-5 border-[1px] border-[#D0D5DD] rounded-xl">
            <h1 className=" w-full text-[#353535] py-4 leading-6 text-[16px] font-medium">
              Tambah Sub-Kategori
            </h1>
            <form onSubmit={addKategori} className="mt-6 w-full">
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Nama Sub-Kategori
                </label>
                <span
                  className={
                    errname ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgname && msgname.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Kategori"
                  />
                </div>
              </div>
              <div className="w-full bg-white">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Kategori
                </label>
                <span
                  className={
                    errkategori
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msgkategori && msgkategori.map((item) => item)}
                </span>
                <div>
                  <Select
                    className="text-[16px] font-normal leading-5 text-[#667085] w-full mt-3 rounded-lg"
                    onSelect={handleSelect}
                    placeholder="Pilih Kategori"
                    optionLabelProp="label"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        {next !== null || prev !== null ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px",
                            }}
                          >
                            {prev ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className="mr-20"
                              >
                                Sebelumnya
                              </button>
                            ) : null}
                            {next ? (
                              <button
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                Selanjutnya
                              </button>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  >
                    {kategori.map((option) => (
                      <Select.Option
                        label={option.name}
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Deskripsi
                </label>
                <span
                  className={
                    errdeskripsi
                      ? "mt-1 flex text-[12px] text-red-500"
                      : "hidden"
                  }
                >
                  {msgdeskripsi && msgdeskripsi.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="deskripsi"
                    value={deskripsi}
                    onChange={(e) => setDeskripsi(e.target.value)}
                    placeholder="deskripsi"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Berat
                </label>
                <span
                  className={
                    errweight ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgweight && msgweight.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="weight"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    placeholder="... Kg"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Harga Beli
                </label>
                <span
                  className={
                    errprice ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgprice && msgprice.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Rp. ... "
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Harga Jual
                </label>
                <span
                  className={
                    errsell ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgsell && msgsell.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="sell"
                    value={sell}
                    onChange={(e) => setSell(e.target.value)}
                    placeholder="Rp. ... "
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Berat Minimal
                </label>
                <span
                  className={
                    errlimit ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msglimit && msglimit.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="text"
                    id="limit"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    placeholder="... Kg"
                  />
                </div>
              </div>
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Pengolahan Pada Sampah
                </label>
                <span
                  className={
                    errtreat ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgtreat && msgtreat.map((item) => item)}
                </span>
                <div>
                  <div>
                    <label>
                      <input
                        className="mt-[6px] font-normal leading-5 p-3 mr-4"
                        type="radio"
                        value="Reuse"
                        checked={treat === "Reuse"}
                        onChange={handleChange}
                      />
                      <span className="text-[16px] text-[#667085] ">
                        {" "}
                        Reuse{" "}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        className="mt-[6px] font-normal leading-5 p-3 mr-4"
                        type="radio"
                        value="Recycle"
                        checked={treat === "Recycle"}
                        onChange={handleChange}
                      />
                      <span className="text-[16px] text-[#667085] ">
                        {" "}
                        Recycling{" "}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        className="mt-[6px] font-normal leading-5 p-3 mr-4"
                        type="radio"
                        value="Burning"
                        checked={treat === "Burning"}
                        onChange={handleChange}
                      />
                      <span className="text-[16px] text-[#667085] ">
                        {" "}
                        Burning{" "}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Tanggal Registrasi
                </label>
                <span
                  className={
                    errdate ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgdate && msgdate.map((item) => item)}
                </span>
                <div>
                  <input
                    className="text-[16px] mt-[6px] font-normal leading-5 text-[#667085] border-[1px] border-[#D0D5DD] bg-white w-full h-[44px] p-3 rounded-lg"
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
              </div> */}
              <div className="w-full bg-white mt-[10px]">
                <label className="text-[14px] font-medium leading-5 text-[#353535]">
                  Icon
                </label>
                <span
                  className={
                    erricon ? "mt-1 flex text-[12px] text-red-500" : "hidden"
                  }
                >
                  {msgicon && msgicon.map((item) => item)}
                </span>
                <div>
                  <Dragger
                    name="file"
                    multiple={false}
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    onChange={handleFileChange}
                    className="upload-container"
                    beforeUpload={beforeUpload}
                  >
                    <p className="ant-upload-drag-icon justify-center items-center flex">
                      <img src={upload} alt="upload" />
                    </p>
                    <p className="ant-upload-text">
                      klik untuk mengunggah atau seret dan lepas
                    </p>
                    <p className="ant-upload-hint">
                      PNG atau JPG (max. 800x400px)
                    </p>
                    <div className="flex justify-center">
                      {iconFile && (
                        <img
                          src={URL.createObjectURL(iconFile.originFileObj)}
                          alt="icon"
                          className="w-24 mt-4"
                        />
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
              <div className="flex justify-end items-end w-full">
                <button className="mt-[26px] p-[10px] text-white leading-6 font-medium text-[16px] w-[169px] h-[44px] bg-[#1F7325] rounded-lg shadow">
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubkategori;
